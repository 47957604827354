import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import { MAX_FIELD_BENCH_PLAYERS } from "../../helpers/bench";
import { getReserveList, getReservePosition } from "../../helpers/squad";
import { getPlayerPhotoUrl } from "../../helpers/utils";
import SquadItem from "./item";
import styles from "./index.module.scss";
import { Button } from "react-bootstrap";
import {
  ATTACKER,
  DEFENDER,
  GOALKEEPER,
  MIDFIELDER,
  tgChatLink,
} from "../../constants";
import Modal from "react-bootstrap/Modal";
import { getPlayerById, isPlayerExist } from "../../helpers/player";
import { SquadContext } from "../../providers/SquadProvider";
import { AdminContext } from "../../providers/AdminProvider";
import { SettingsContext } from "../../providers/SettingsProvider";
import { MessageContext } from "../../providers/MessageProvider";
import isEqual from "lodash.isequal";
import { getPlayerShortTeam, getShortPosition } from "../../helpers/player";
import FootballField from "../FootballField";
import { parseScheme } from "../../helpers/scheme";
import { getReserveIndex } from "../FootballField";
import SchemePicker from "../SchemePicker";
import PlayerRoundInfo from "../../modals/PlayerRoundInfo";
import BenchPrioritiesModal from "../../modals/BenchPriorities";

export const isGoalKeeperSelected = (selectedItem, idx) => {
  if (selectedItem === null) return;
  const { position, index, isReserve } = selectedItem;
  return position === GOALKEEPER && index === idx && !isReserve;
};

export const isDefenderSelected = (selectedItem, idx) => {
  if (selectedItem === null) return;
  const { position, index, isReserve } = selectedItem;
  return position === DEFENDER && index === idx && !isReserve;
};

export const isMidFielderSelected = (selectedItem, idx) => {
  if (selectedItem === null) return;
  const { position, index, isReserve } = selectedItem;
  return position === MIDFIELDER && index === idx && !isReserve;
};

export const isAttackerSelected = (selectedItem, idx) => {
  if (selectedItem === null) return;
  const { position, index, isReserve } = selectedItem;
  return position === ATTACKER && index === idx && !isReserve;
};

export const isReserveSelected = (selectedItem, idx, reservePosition) => {
  if (selectedItem === null) return;
  const { position, index, isReserve } = selectedItem;
  return position === reservePosition && index === idx && isReserve;
};

export const isCapitan = (squad, playerId) => {
  return squad?.cap === playerId;
};

export const isViceCapitan = (squad, playerId) => {
  return squad?.vcap === playerId;
};

const Squad = ({
  squad,
  onShowPlayerSearch,
  viewToggle,
  onShowPlayerInfo,
  historicalSquad,
  historicalDetails,
  isHistoricalRound,
  historicalSquadLoading,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [player, setPlayer] = useState(null);
  const [historicalPlayerModal, setHistoricalPlayerModal] = useState(false);
  const [benchPrioritiesModal, setBenchPrioritiesModal] = useState(false);
  const [historicalPlayer, setHistoricalPlayer] = useState(null);

  const attackersRef = useRef();
  const benchRef = useRef();
  const defendersRef = useRef();
  const midfieldersRef = useRef();
  const goalkeeperRef = useRef();

  const {
    setAction,
    setOutPlayer,
    outPlayer,
    setInPlayer,
    selectedItem,
    setSelectedItem,
    setCapitan,
    setViceCapitan,
    removePlayer,
    clearAction,
    action,
    allowedTransfers,
  } = useContext(SquadContext);

  const { activeDeadline, teams, selectedRound } = useContext(SettingsContext);
  const { showMessage } = useContext(MessageContext);

  const { players, setSearchPosition } = useContext(AdminContext);

  const isFullBench = useMemo(
    () => squad?.bpr?.length === MAX_FIELD_BENCH_PLAYERS,
    [squad]
  );

  useEffect(() => {
    if (!selectedItem) return;
    const newPlayer = getPlayerById(players, selectedItem.playerId);
    setPlayer(newPlayer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const sellPlayer = () => {
    removePlayer(outPlayer);
    setShowModal(false);
    clearAction();
  };

  function scrollToTargetAdjusted(element, viewToggle) {
    const headerOffset = viewToggle ? 60 : 220;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }

  const onHandleAutoScroll = (player, reserveList) => {
    const isBenchPlayer = reserveList.includes(player.id);
    let targetElement;

    if (isBenchPlayer) {
      if (player.position === GOALKEEPER) {
        targetElement = goalkeeperRef;
      } else if (player.position === DEFENDER) {
        targetElement = defendersRef;
      } else if (player.position === MIDFIELDER) {
        targetElement = midfieldersRef;
      } else if (player.position === ATTACKER) {
        if (viewToggle) {
          targetElement = attackersRef;
        } else {
          return;
        }
      }
      scrollToTargetAdjusted(targetElement.current, viewToggle);
    } else {
      benchRef.current.scrollIntoView({ block: viewToggle ? "center" : "end" });
    }
  };

  const substitutePlayer = (player, reserveList) => {
    onHandleAutoScroll(player, reserveList);
    setAction("substitute");
    setShowModal(false);
  };

  const makeCapitan = () => {
    setCapitan(outPlayer);
    setShowModal(false);
    clearAction();
  };

  const makeViceCapitan = () => {
    setViceCapitan(outPlayer);
    setShowModal(false);
    clearAction();
  };

  const onHideBenchPrioritiesModal = () => {
    setBenchPrioritiesModal(false);
  };

  const showBenchPrioritiesModal = () => {
    setBenchPrioritiesModal(true);
  };

  const onHideHistoricalPlayerModal = () => {
    setHistoricalPlayerModal(false);
  };

  const onHistoricalItemClick = (options) => {
    const { playerId } = options;
    const player = getPlayerById(players, playerId);
    setHistoricalPlayer(player);
    setHistoricalPlayerModal(true);
  };

  const onItemClick = (options) => {
    const { playerId, isFantomPlayer } = options;

    if (!activeDeadline && !isPlayerExist(playerId)) {
      showMessage(
        "danger",
        "Управління складом буде розблоковано після завершення поточного туру."
      );
      return;
    }

    if (!activeDeadline) {
      if (!isFantomPlayer) {
        onShowPlayerInfo(options.playerId);
      }
      return;
    }

    if (isPlayerExist(playerId) && !isFantomPlayer) {
      if (!outPlayer) {
        setShowModal(true);
        setSelectedItem({ ...options });
        setOutPlayer({ ...options });
      } else if (action === "substitute") {
        setInPlayer({ ...options });
      }
    } else {
      setAction("add");
      setSelectedItem({ ...options });
      setOutPlayer({ ...options });
      onShowPlayerSearch();
      setSearchPosition(options.position);
    }

    if (isEqual(options, selectedItem)) {
      setSelectedItem(null);
    }
  };

  const onHideActionsModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    clearAction();
  };

  const showPlayerInfo = (playerId) => {
    //onHideActionsModal();
    onShowPlayerInfo(playerId);
  };

  const isGoalkeeperClickAllowed =
    !action || (action === "substitute" && outPlayer.position === GOALKEEPER);
  const isDefenderClickAllowed =
    !action || (action === "substitute" && outPlayer.position === DEFENDER);

  const isMidFielderClickAllowed =
    !action || (action === "substitute" && outPlayer.position === MIDFIELDER);

  const isAttackerClickAllowed =
    !action || (action === "substitute" && outPlayer.position === ATTACKER);

  const isReservedClickAllowed = (index, outPlayer, action) => {
    return (
      !action ||
      (action === "substitute" &&
        outPlayer.position === getReservePosition(index))
    );
  };

  const reserveList = getReserveList(squad);

  const isHistoricalView = isHistoricalRound;

  return (
    <>
      <h5 className={`${styles.titleRow}`}>
        <span>
          <i className="bi bi-person-fill"></i>&nbsp;Cтартовий склад
        </span>
        <div className={styles.rightWrapper}>
          <div
            onClick={() => window.open(tgChatLink, "_blank")}
            className={styles.chatTile}
          >
            <span className={styles.chatCaption}>ЧАТ</span>
            <i className="bi bi-chat-left-text-fill"></i>
          </div>
          <SchemePicker active={activeDeadline} hidden={isHistoricalView} />
        </div>
      </h5>
      {!viewToggle || isHistoricalView ? (
        <FootballField
          goalkeeperRef={goalkeeperRef}
          defendersRef={defendersRef}
          midfieldersRef={midfieldersRef}
          attackersRef={attackersRef}
          benchRef={benchRef}
          squad={isHistoricalView ? historicalSquad : squad}
          onItemClick={isHistoricalView ? onHistoricalItemClick : onItemClick}
          isGoalkeeperClickAllowed={
            isHistoricalView ? true : isGoalkeeperClickAllowed
          }
          isDefenderClickAllowed={
            isHistoricalView ? true : isDefenderClickAllowed
          }
          isMidfielderClickAllowed={
            isHistoricalView ? true : isMidFielderClickAllowed
          }
          isAttackerClickAllowed={
            isHistoricalView ? true : isAttackerClickAllowed
          }
          isReservedClickAllowed={
            isHistoricalView ? true : isReservedClickAllowed
          }
          action={isHistoricalView ? "" : action}
          selectedItem={isHistoricalView ? null : selectedItem}
          outPlayer={isHistoricalView ? null : outPlayer}
          selectedDetails={isHistoricalView ? historicalDetails : undefined}
          isHistoricalView={isHistoricalView}
          inactive={isHistoricalView}
          loading={isHistoricalView ? historicalSquadLoading : false}
          showBenchPrioritiesModal={showBenchPrioritiesModal}
        />
      ) : (
        <div>
          <div style={{ marginTop: 8 }}>
            <h5 ref={goalkeeperRef} className={styles.innerTitle}>
              <i className="bi bi-person-fill"></i>&nbsp;Голкіпер
            </h5>
            {squad.main["GK"].map((player, index) => (
              <SquadItem
                key={index}
                playerId={player}
                position={GOALKEEPER}
                index={index}
                isReserve={false}
                selected={isGoalKeeperSelected(selectedItem, index)}
                onItemClick={onItemClick}
                isClickAllowed={isGoalkeeperClickAllowed}
                isSubstitution={action === "substitute"}
                isCapitan={isCapitan(squad, player)}
                isViceCapitan={isViceCapitan(squad, player)}
                cap={squad?.cap}
                vcap={squad?.vcap}
              />
            ))}
          </div>
          <div>
            <h5 ref={defendersRef} className={styles.innerTitle}>
              <i className="bi bi-person-fill"></i>&nbsp;Захисники
            </h5>
            {squad.main["D"].map((player, index) => (
              <SquadItem
                key={index}
                playerId={player}
                position={DEFENDER}
                index={index}
                isReserve={false}
                selected={isDefenderSelected(selectedItem, index)}
                onItemClick={onItemClick}
                isClickAllowed={isDefenderClickAllowed}
                isSubstitution={action === "substitute"}
                isCapitan={isCapitan(squad, player)}
                isViceCapitan={isViceCapitan(squad, player)}
                cap={squad?.cap}
                vcap={squad?.vcap}
              />
            ))}
          </div>
          <div>
            <h5 ref={midfieldersRef} className={styles.innerTitle}>
              <i className="bi bi-person-fill"></i>&nbsp;Півзахисники
            </h5>
            {squad.main["MD"].map((player, index) => (
              <SquadItem
                key={index}
                playerId={player}
                position={MIDFIELDER}
                index={index}
                isReserve={false}
                selected={isMidFielderSelected(selectedItem, index)}
                onItemClick={onItemClick}
                isClickAllowed={isMidFielderClickAllowed}
                isSubstitution={action === "substitute"}
                isCapitan={isCapitan(squad, player)}
                isViceCapitan={isViceCapitan(squad, player)}
                cap={squad?.cap}
                vcap={squad?.vcap}
              />
            ))}
          </div>
          <div>
            <h5 ref={attackersRef} className={styles.innerTitle}>
              <i className="bi bi-person-fill"></i>&nbsp;Нападники
            </h5>
            {squad.main["ST"].map((player, index) => (
              <SquadItem
                key={index}
                playerId={player}
                position={ATTACKER}
                index={index}
                isReserve={false}
                selected={isAttackerSelected(selectedItem, index)}
                onItemClick={onItemClick}
                isClickAllowed={isAttackerClickAllowed}
                isSubstitution={action === "substitute"}
                isCapitan={isCapitan(squad, player)}
                isViceCapitan={isViceCapitan(squad, player)}
                cap={squad?.cap}
                vcap={squad?.vcap}
              />
            ))}
          </div>
          <div className={styles.reserveItems}>
            <h5 ref={benchRef} className={styles.flex}>
              <span>
                <i className={`bi bi-person-fill`}></i>&nbsp;Запасні
              </span>
              {activeDeadline ? (
                isFullBench && (
                  <Button
                    className={styles.bprBtn}
                    variant="outline-secondary"
                    type="small"
                    onClick={showBenchPrioritiesModal}
                  >
                    <i className={`bi bi-gear-fill ${styles.bprIcon}`}></i>
                    &nbsp;Пріоритети
                  </Button>
                )
              ) : (
                <span className={styles.substHint}>
                  Автозаміни відбуваються після останнього матчу туру
                </span>
              )}
            </h5>
            {reserveList?.map((player, index) => {
              const scheme = parseScheme(squad);
              const position = getReservePosition(scheme, index);
              const playerIndex = getReserveIndex(
                player,
                squad?.reserve,
                position
              );
              return (
                <SquadItem
                  key={index}
                  playerId={player}
                  viewOrder={squad?.bpr?.indexOf(player)}
                  position={position}
                  index={playerIndex}
                  isReserve={true}
                  selected={isReserveSelected(
                    selectedItem,
                    playerIndex,
                    position
                  )}
                  onItemClick={onItemClick}
                  isClickAllowed={
                    !action ||
                    (action === "substitute" &&
                      outPlayer.position === position &&
                      !reserveList
                        .filter((pl) => pl !== player)
                        .includes(outPlayer.playerId))
                  }
                  isSubstitution={action === "substitute"}
                  bpr={squad?.bpr}
                />
              );
            })}
          </div>
        </div>
      )}
      <Modal
        size="sm"
        show={showModal}
        onHide={onHideActionsModal}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            <div className={styles.photoCnt}>
              <img
                className={styles.photo}
                src={getPlayerPhotoUrl(player)}
                alt=""
              />
              <div className={styles.info}>
                <div className={styles.name}>
                  <div>{player?.FirstName}</div>
                  <div>{player?.LastName}</div>
                </div>
                <div className={styles.hint}>
                  {getPlayerShortTeam(teams, player)} |{" "}
                  {getShortPosition(player?.position)}
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Button
              variant="success"
              className={styles.action}
              onClick={() => substitutePlayer(player, reserveList)}
            >
              <div className={styles.btnContent}>
                <i className="bi bi-arrow-repeat"></i>&nbsp;Замінити
              </div>
            </Button>
          </div>
          <div>
            {allowedTransfers > 0 && (
              <Button
                variant="danger"
                className={styles.action}
                onClick={sellPlayer}
              >
                <div className={styles.btnContent}>
                  <i className="bi bi-bag"></i>&nbsp;Продати
                </div>
              </Button>
            )}
          </div>

          <div>
            {!isCapitan(squad, selectedItem?.playerId) &&
              !selectedItem?.isReserve && (
                <Button
                  variant="warning"
                  className={styles.action}
                  onClick={makeCapitan}
                >
                  <div className={styles.btnContent}>
                    <i className="bi bi-bookmark-check-fill"></i>
                    &nbsp;Призначити капітаном
                  </div>
                </Button>
              )}
          </div>

          <div>
            {!isViceCapitan(squad, selectedItem?.playerId) &&
              !selectedItem?.isReserve && (
                <Button
                  variant="dark"
                  className={styles.action}
                  onClick={makeViceCapitan}
                >
                  <div className={styles.btnContent}>
                    <i className="bi bi-bookmark-check-fill"></i>
                    &nbsp;Призначити віце-капітаном
                  </div>
                </Button>
              )}
          </div>

          <div>
            <Button
              variant="info"
              className={styles.action}
              onClick={() => showPlayerInfo(player.PlayerId)}
            >
              <div className={styles.btnContent}>
                <i className="bi bi-info-circle"></i>&nbsp;Інформація
              </div>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <PlayerRoundInfo
        show={historicalPlayerModal}
        onHide={onHideHistoricalPlayerModal}
        player={historicalPlayer}
        round={selectedRound}
      />
      <BenchPrioritiesModal
        show={benchPrioritiesModal}
        onHide={onHideBenchPrioritiesModal}
      />
    </>
  );
};

export default Squad;
