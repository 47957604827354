import Header from "./components/Header";
import Administration from "./pages/Administration";
import Index from "./pages/Index";
import Login from "./pages/Login";
import Home, { isPwa } from "./pages/Home";
import PrivateRoute from "./components/PrivateRoute";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Standings from "./pages/Standings";
import Details from "./pages/Details";
import RoundDetails from "./pages/RoundDetails";
import Logout from "./components/Logout";
import Sponsors from "./pages/Sponsors";
import Leagues from "./pages/Leagues";
import TermsOfUse from "./components/TermsOfUse";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { useState, useEffect, lazy } from "react";
import { Button } from "react-bootstrap";
import Rules from "./pages/Rules";
import Error from "./pages/Error";
import Maintenance from "./pages/Maintenance";
import OneSignal from "react-onesignal";
import { Suspense } from "react";
import PrivateLeagues from "./pages/PrivateLeagues";
import ClientCache from "./cache";
import AccessDenied from "./pages/AccessDenied";
const Stats = lazy(() => import("./pages/Stats"));

const App = () => {
  const [scrollToTopButton, setScrollToTopButton] = useState(false);
  const [pageClass, setPageClass] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.hostname !== "localhost") {
      OneSignal.init({
        appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
      });
    }
    const cache = ClientCache.getInstance();

    return () => {
      cache.destroy();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 150) {
        setScrollToTopButton(true);
      } else {
        setScrollToTopButton(false);
      }
    });

    window.addEventListener("error", function (event) {
      if (event.error.hasBeenCaught !== undefined) {
        return false;
      }
      event.error.hasBeenCaught = true;
      window.location.href = "/support";
    });
  }, [navigate]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  useEffect(() => {
    if (pathname === "/home") {
      setPageClass(" home");
    } else {
      setPageClass("");
    }
  }, [pathname]);

  return (
    <div className={`App${pageClass || ""}`}>
      {pathname !== "/" && pathname !== "/login" && <Header />}
      <Routes>
        <Route path="/" element={<Index />} />
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/standings/:round"
          element={
            <PrivateRoute>
              <Standings />
            </PrivateRoute>
          }
        />
        <Route
          path="/leagues"
          element={
            <PrivateRoute>
              <Leagues />
            </PrivateRoute>
          }
        />
        <Route
          path="/private-leagues"
          element={
            <PrivateRoute>
              <PrivateLeagues />
            </PrivateRoute>
          }
        />
        <Route
          path="/details/:userId"
          element={
            <PrivateRoute>
              <Details />
            </PrivateRoute>
          }
        />
        <Route
          path="/rounds/:roundNumber"
          element={
            <PrivateRoute>
              <RoundDetails />
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route
          path="/administration"
          element={
            <PrivateRoute>
              <Suspense fallback={<div>Loading...</div>}>
                <Administration />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/stats"
          element={
            <PrivateRoute>
              <Suspense fallback={<div>Loading...</div>}>
                <Stats />
              </Suspense>
            </PrivateRoute>
          }
        ></Route>
        <Route path="/sponsors" element={<Sponsors />}></Route>
        <Route path="/terms-of-service" element={<TermsOfUse />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/rules" element={<Rules />}></Route>
        <Route path="/support" element={<Error />}></Route>
        <Route path="/maintenance" element={<Maintenance />}></Route>
        <Route path="/access-denied" element={<AccessDenied />}></Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {scrollToTopButton && (
        <Button
          className={`scrollToTop ${isPwa() ? "pwa" : ""}`}
          onClick={scrollToTop}
          size="lg"
        >
          <i className="bi bi-arrow-up"></i>
        </Button>
      )}
    </div>
  );
};

export default App;
