import { TRANSFERS_THRESHOLD } from "../constants";

export const getFirstName = (playerName) => {
  if (!playerName) return "";
  const [firstName, lastName] = playerName?.split(" ");
  return `${firstName} ${lastName.charAt(0)}`;
};

export const getPlayerName = (player) => {
  const prefix = player.FirstName ? player.FirstName.charAt(0) + ". " : "";

  const sufix = player.LastName;
  return prefix + sufix;
};

export const isAdmin = (user) => {
  return Boolean(user?.me?.isAdmin);
};

export const isLoggedIn = () => {
  return Boolean(localStorage.getItem("access_token"));
};

export const isMe = (me, userId) => {
  return me?.userId === userId;
};

export const getAllowedTransfers = (allowedTransfers) => {
  let result = 0;
  if (allowedTransfers > TRANSFERS_THRESHOLD) {
    result = "Б/О";
  } else {
    result = allowedTransfers;
  }
  return result;
};

export const getRank = (standings, userId) => {
  return standings.find((entry) => entry.UserId === userId)?.Rank || "-";
};

export const getPoints = (standings, userId) => {
  return standings.find((entry) => entry.UserId === userId)?.Points || "-";
};

export const getSplittedName = (fullName = "") => {
  const chunks = fullName.split(" ");
  if (chunks.length > 1) {
    return (
      <>
        {chunks[0]}
        <br />
        {chunks[1] + (chunks[2] ? ` ${chunks[2]}` : "")}
      </>
    );
  } else {
    return fullName;
  }
};

export const clearLocalStorage = () => {
  window.localStorage.removeItem("refresh_token");
  window.localStorage.removeItem("access_token");
  window.localStorage.removeItem("authType");
  window.localStorage.removeItem("redirectPath");
  window.localStorage.removeItem("redirectQuery");
  window.localStorage.removeItem("expires_at");
  window.localStorage.removeItem("cache");
  window.localStorage.removeItem("players");
};

export const getMonthLabel = (key) => {
  const monthLabels = {
    Jan: "Січень",
    Feb: "Лютий",
    Mar: "Березень",
    Apr: "Квітень",
    May: "Травень",
    Jun: "Червень",
    Jul: "ЛИПЕНЬ",
    Aug: "СЕРПЕНЬ",
    Sep: "ВЕРЕСЕНЬ",
    Oct: "ЖОВТЕНЬ",
    Nov: "ЛИСТОПАД",
    Dec: "ГРУДЕНЬ",
  };

  return monthLabels[key] || "";
};
