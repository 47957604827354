import React, { useState, useContext } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import Form from "react-bootstrap/Form";
import Container from "../Container";
import { useMutation } from "react-query";
import Button from "react-bootstrap/Button";
import API from "../../api";
import { getPlayerPhotoUrl } from "../../helpers/utils";
import { SettingsContext } from "../../providers/SettingsProvider";
import {
  ATTACKER,
  DEFENDER,
  getTeamDropdownItems,
  GOALKEEPER,
  MIDFIELDER,
} from "../../constants";
import Select from "react-select";

const positions = [
  { value: GOALKEEPER, label: "Голкіпер" },
  { value: DEFENDER, label: "Захисник" },
  { value: MIDFIELDER, label: "Півзахисник" },
  { value: ATTACKER, label: "Нападник" },
];

const EditPlayer = ({
  selectedPlayer,
  players,
  filteredPlayers,
  setPlayers,
  setFilteredPlayers,
  page,
  gotoPage,
  handleClose,
}) => {
  const [firstName, setFirstName] = useState(selectedPlayer.FirstName);
  const [lastName, setLastName] = useState(selectedPlayer.LastName);
  const [cost, setCost] = useState(selectedPlayer.Cost);
  const [status, setStatus] = useState(selectedPlayer.Active);
  const [hidden, setHidden] = useState(selectedPlayer.Hidden);
  const [teamId, setTeamId] = useState(selectedPlayer.teamId);
  const [position, setPosition] = useState(selectedPlayer.position);
  const [number, setNumber] = useState(selectedPlayer.number);

  const { teams: teamList } = useContext(SettingsContext);

  const mutation = useMutation({
    mutationFn: (playerInfo) => {
      return API.savePlayerInfo(playerInfo);
    },
    onSuccess: (response, variables, context) => {
      const newPlayers = players.map((player) =>
        player.id === variables.PlayerId ? { ...player, ...variables } : player
      );
      const newFilteredPlayers = filteredPlayers.map((filteredPlayer) =>
        filteredPlayer.id === variables.PlayerId
          ? { ...filteredPlayer, ...variables }
          : filteredPlayer
      );
      setPlayers(newPlayers);
      setFilteredPlayers(newFilteredPlayers);
      setTimeout(() => gotoPage(page), 100);
      handleClose();
    },
  });

  const onChangePlayerFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const onChangePlayerLastName = (e) => {
    setLastName(e.target.value);
  };

  const onChangePlayerNumber = (e) => {
    setNumber(+e.target.value);
  };

  const onChangePlayerStatus = (e) => {
    setStatus(e.target.checked);
  };

  const onChangeVisibilityStatus = (e) => {
    setHidden(e.target.checked);
  };

  const onChangePlayerCost = (e) => {
    setCost(e.target.value);
  };

  const teamsOptions = getTeamDropdownItems(teamList);

  const onChangeTeam = ({ value }) => {
    setTeamId(+value);
  };

  const onChangePosition = ({ value }) => {
    setPosition(value);
  };

  return (
    selectedPlayer && (
      <Container style={{ paddingTop: 0, fontSize: "1.3rem" }}>
        <Grid>
          <Row style={{ paddingBottom: 16 }}>
            <Col xs={12} md={4}>
              <img
                style={{ objectFit: "contain" }}
                width={40}
                height={40}
                src={getPlayerPhotoUrl(selectedPlayer)}
                alt=""
              />
              &nbsp;{selectedPlayer?.name}
            </Col>
            <Col xs={12} md={4}>
              <Select
                defaultValue={teamsOptions.find(
                  (option) => option.value === selectedPlayer.teamId
                )}
                options={getTeamDropdownItems(teamList)}
                onChange={onChangeTeam}
              />
            </Col>
            <Col xs={12} md={3}>
              <Select
                defaultValue={positions.find(
                  (option) => option.value === selectedPlayer.position
                )}
                options={positions}
                onChange={onChangePosition}
              />
            </Col>
            <Col xs={12} md={1}>
              <Form.Control
                style={{ fontSize: "1.3rem" }}
                type="number"
                placeholder="Номер"
                onChange={onChangePlayerNumber}
                defaultValue={selectedPlayer?.number}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Ім'я гравця:</Form.Label>
                <Form.Control
                  style={{ fontSize: "1.3rem" }}
                  type="text"
                  placeholder="Ім'я"
                  onChange={onChangePlayerFirstName}
                  defaultValue={selectedPlayer?.FirstName}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Прізвище гравця:</Form.Label>
                <Form.Control
                  style={{ fontSize: "1.3rem" }}
                  type="text"
                  placeholder="Прізвище"
                  onChange={onChangePlayerLastName}
                  defaultValue={selectedPlayer?.LastName}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label>Вартість гравця:</Form.Label>
                <Form.Control
                  style={{ fontSize: "1.3rem" }}
                  type="text"
                  placeholder="Вартість"
                  onChange={onChangePlayerCost}
                  defaultValue={selectedPlayer?.Cost}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  onChange={onChangePlayerStatus}
                  defaultChecked={selectedPlayer?.Active}
                  label="Активований"
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  onChange={onChangeVisibilityStatus}
                  defaultChecked={selectedPlayer?.Hidden}
                  label="Прихований"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ paddingTop: 16 }}>
            <Col>
              <Button
                style={{ fontSize: "1.3rem" }}
                onClick={() =>
                  mutation.mutate({
                    FirstName: firstName,
                    LastName: lastName,
                    Cost: cost,
                    Active: status,
                    Hidden: hidden,
                    PlayerId: selectedPlayer.PlayerId || selectedPlayer.id,
                    teamId: teamId,
                    position: position,
                    number: number,
                  })
                }
              >
                Зберегти
              </Button>
            </Col>
          </Row>
        </Grid>
      </Container>
    )
  );
};

export default EditPlayer;
