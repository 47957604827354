import React from "react";
import { Badge } from "react-bootstrap";
import styles from "./index.module.scss";

const getBadgeVariant = (passed) => {
  if (passed < 4) {
    return "primary";
  } else if (passed < 8) {
    return "warning";
  } else if (passed === 11) {
    return "secondary";
  } else {
    return "danger";
  }
};

const LiveStatus = ({ passed, all }) => {
  const badgeVariant = getBadgeVariant(passed);
  const isDanger = badgeVariant === "danger";
  const isSecondary = badgeVariant === "secondary";
  return (
    <Badge
      bg={badgeVariant}
      className={styles.root}
      style={{ color: isDanger || isSecondary ? "#fff" : "#000" }}
    >
      <i className="bi bi-check"></i>
      {passed}/{all}
    </Badge>
  );
};

export default LiveStatus;
