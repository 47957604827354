import React, {
  createContext,
  useReducer,
  useState,
  useEffect,
  useContext,
} from "react";
import { ALL_MONEY, ALL_PLAYERS, positionKeysMap } from "../constants";
import {
  getSquadDiffNumber,
  getTransfers,
  squadToList,
} from "../helpers/squad";
import { getPlayerById } from "../helpers/player";
import { AdminContext } from "./AdminProvider";
import { AuthContext } from "./AuthProvider";
import isEqual from "lodash.isequal";
import { getBpr } from "../helpers/bench";

const initialPlayer = {
  playerId: null,
  position: null,
};

const getCapitanAfterSubstitution = (
  cap,
  inPlayer,
  outPlayer,
  isSameSquadType
) => {
  if (cap === outPlayer.playerId && !isSameSquadType) {
    return inPlayer.playerId;
  } else if (cap === inPlayer.playerId && !isSameSquadType) {
    return outPlayer.playerId;
  }

  return cap;
};

const getViceCapitanAfterSubstitution = (
  vcap,
  inPlayer,
  outPlayer,
  isSameSquadType
) => {
  if (vcap === outPlayer.playerId && !isSameSquadType) {
    return inPlayer.playerId;
  } else if (vcap === inPlayer.playerId && !isSameSquadType) {
    return outPlayer.playerId;
  }

  return vcap;
};

const updatedSquad = (state, payload) => {
  let updatedSquad = {};
  const { outPlayer, inPlayer } = payload;
  const isMainSquad = !outPlayer?.isReserve;
  const targetPosition = positionKeysMap[outPlayer?.position];
  const targetIndex = outPlayer?.index;

  if (isMainSquad) {
    updatedSquad = {
      ...state,
      main: {
        ...state.main,
        [targetPosition]: state.main[targetPosition].map((item, index) =>
          index === targetIndex ? inPlayer.playerId : item
        ),
      },
    };
  } else {
    updatedSquad = {
      ...state,
      reserve: {
        ...state.reserve,
        [targetPosition]: state.reserve[targetPosition].map((item, index) =>
          index === targetIndex ? inPlayer.playerId : item
        ),
      },
    };
  }

  return { ...updatedSquad, bpr: getBpr(updatedSquad.reserve, state.bpr) };
};

const updatedSquadAfterDeletion = (state, payload) => {
  let updatedSquad = {};
  const { outPlayer } = payload;
  const isMainSquad = !outPlayer?.isReserve;
  const targetPosition = positionKeysMap[outPlayer?.position];
  const targetIndex = outPlayer?.index;

  if (isMainSquad) {
    updatedSquad = {
      ...state,
      main: {
        ...state.main,
        [targetPosition]: state.main[targetPosition].map((item, index) =>
          index === targetIndex ? null : item
        ),
      },
    };
  } else {
    updatedSquad = {
      ...state,
      reserve: {
        ...state.reserve,
        [targetPosition]: state.reserve[targetPosition].map((item, index) =>
          index === targetIndex ? null : item
        ),
      },
    };
  }

  return {
    ...updatedSquad,
    cap: state.cap === outPlayer.playerId ? null : state.cap,
    vcap: state.vcap === outPlayer.playerId ? null : state.vcap,
    bpr: getBpr(updatedSquad.reserve, state.bpr),
  };
};

const updatedSquadAfterSubstitution = (state, payload) => {
  let updatedSquad = {};
  const { outPlayer, inPlayer } = payload;
  const isMainSquadOut = !outPlayer?.isReserve;
  const targetPositionOut = positionKeysMap[outPlayer?.position];
  const targetIndexOut = outPlayer?.index;

  const isMainSquadIn = !inPlayer?.isReserve;
  const targetPositionIn = positionKeysMap[inPlayer?.position];
  const targetIndexIn = inPlayer?.index;

  const isSameSquadType = isMainSquadOut && isMainSquadIn;

  if (isMainSquadOut) {
    updatedSquad = {
      ...state,
      main: {
        ...state.main,
        [targetPositionOut]: state.main[targetPositionOut].map((item, index) =>
          index === targetIndexOut ? inPlayer.playerId : item
        ),
      },
    };
  } else {
    updatedSquad = {
      ...state,
      reserve: {
        ...state.reserve,
        [targetPositionOut]: state.reserve[
          targetPositionOut
        ].map((item, index) =>
          index === targetIndexOut ? inPlayer.playerId : item
        ),
      },
    };
  }

  if (isMainSquadIn) {
    updatedSquad = {
      ...updatedSquad,
      main: {
        ...updatedSquad.main,
        [targetPositionIn]: updatedSquad.main[
          targetPositionIn
        ].map((item, index) =>
          index === targetIndexIn ? outPlayer.playerId : item
        ),
      },
    };
  } else {
    updatedSquad = {
      ...updatedSquad,
      reserve: {
        ...updatedSquad.reserve,
        [targetPositionIn]: updatedSquad.reserve[
          targetPositionIn
        ].map((item, index) =>
          index === targetIndexIn ? outPlayer.playerId : item
        ),
      },
    };
  }

  return {
    ...updatedSquad,
    cap: getCapitanAfterSubstitution(
      state.cap,
      inPlayer,
      outPlayer,
      isSameSquadType
    ),
    vcap: getViceCapitanAfterSubstitution(
      state.vcap,
      inPlayer,
      outPlayer,
      isSameSquadType
    ),
    bpr: getBpr(updatedSquad.reserve, state.bpr),
  };
};

const updatedSquadAfterCapitanAssignment = (state, payload) => {
  const { outPlayer } = payload;

  return {
    ...state,
    cap: outPlayer.playerId,
    vcap: outPlayer.playerId === state.vcap ? null : state.vcap,
  };
};

const updatedSquadAfterViceCapitanAssignment = (state, payload) => {
  const { outPlayer } = payload;

  return {
    ...state,
    vcap: outPlayer.playerId,
    cap: outPlayer.playerId === state.cap ? null : state.cap,
  };
};

export const SquadContext = createContext({
  squad: {},
  squadSnapshot: {},
  setSquadSnapshot: () => undefined,
  setSquad: () => undefined,
  action: null,
  setAction: () => undefined,
  outPlayer: initialPlayer,
  setOutPlayer: () => undefined,
  inPlayer: initialPlayer,
  setInPlayer: () => undefined,
  addPlayer: () => undefined,
  removePlayer: () => undefined,
  substitutePlayer: () => undefined,
  setCapitan: () => undefined,
  setViceCapitan: () => undefined,
  setBpr: () => undefined,
  clearAction: () => undefined,
  flatList: [],
  teamBalance: ALL_MONEY,
  setTeamBalance: () => undefined,
  teamBalanceSnapshot: ALL_MONEY,
  setTeamBalanceSnapshot: () => undefined,
  playersNumber: ALL_PLAYERS,
  allowedTransfers: null,
  setAllowedTransfers: () => undefined,
  allowedTransfersSnapshot: null,
  setAllowedTransfersSnapshot: () => undefined,
  decAllowedTransfers: () => undefined,
  squadScores: [],
  setSquadScores: () => undefined,
});

function reducer(state, action) {
  switch (action.type) {
    case "set":
      return action.payload;
    case "change":
      return updatedSquad(state, action.payload);
    case "remove":
      return updatedSquadAfterDeletion(state, action.payload);
    case "substitute":
      return updatedSquadAfterSubstitution(state, action.payload);
    case "setCapitan":
      return updatedSquadAfterCapitanAssignment(state, action.payload);
    case "setViceCapitan":
      return updatedSquadAfterViceCapitanAssignment(state, action.payload);
    case "setBpr":
      return { ...state, bpr: action.payload };
    default:
      throw new Error();
  }
}

function SquadProvider({ children }) {
  const [action, setAction] = useState(null);
  const [outPlayer, setOutPlayer] = useState(null);
  const [inPlayer, setInPlayer] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [flatList, setFlatList] = useState([]);
  const [teamBalance, setTeamBalance] = useState(ALL_MONEY);
  const [teamBalanceSnapshot, setTeamBalanceSnapshot] = useState(ALL_MONEY);
  const [playersNumber, setPlayersNumber] = useState(ALL_PLAYERS);
  const [allowedTransfers, setAllowedTransfers] = useState(0);
  const [allowedTransfersSnapshot, setAllowedTransfersSnapshot] = useState(0);
  const [squadScores, setSquadScores] = useState([]);

  const [squadSnapshot, setSquadSnapshot] = useState({});
  const [squadStack, setSquadStack] = useState([]);
  const [state, dispatch] = useReducer(reducer, null);
  const setSquad = (squad) => dispatch({ type: "set", payload: squad });

  const { players } = useContext(AdminContext);

  const { user } = useContext(AuthContext);

  const addPlayer = (outPlayer, inPlayer) => {
    return dispatch({ type: "change", payload: { outPlayer, inPlayer } });
  };

  const removePlayer = (outPlayer) => {
    return dispatch({ type: "remove", payload: { outPlayer } });
  };

  const substitutePlayer = (outPlayer, inPlayer) => {
    return dispatch({ type: "substitute", payload: { outPlayer, inPlayer } });
  };

  const setCapitan = (outPlayer) => {
    return dispatch({ type: "setCapitan", payload: { outPlayer } });
  };

  const setViceCapitan = (outPlayer) => {
    return dispatch({ type: "setViceCapitan", payload: { outPlayer } });
  };

  const setBpr = (bpr) => {
    return dispatch({ type: "setBpr", payload: bpr });
  };

  const clearAction = () => {
    setAction(null);
    setInPlayer(null);
    setOutPlayer(null);
    setSelectedItem(null);
  };

  useEffect(() => {
    if (state !== null) {
      setFlatList(squadToList(state));
      setSquadStack((prevStack) => {
        if (!isEqual(state, prevStack[prevStack.length - 1])) {
          return [...prevStack, state];
        } else {
          return prevStack;
        }
      });
    }
  }, [state]);

  useEffect(() => {
    if (!squadSnapshot || !flatList.length) return;
    const { inTransfers, outTransfers } = getTransfers(
      squadToList(squadSnapshot),
      flatList
    );

    const inTransfersMoney = inTransfers.reduce(
      (acc, current) => acc + Number(getPlayerById(players, current)?.Cost),
      0
    );
    const outTransfersMoney = outTransfers.reduce(
      (acc, current) => acc + Number(getPlayerById(players, current)?.Cost),
      0
    );

    const newBalance =
      teamBalanceSnapshot - inTransfersMoney + outTransfersMoney;

    setTeamBalance(newBalance);

    setPlayersNumber(flatList.filter((playerId) => playerId !== null).length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatList, squadSnapshot, teamBalanceSnapshot]);

  useEffect(() => {
    setAllowedTransfers(user?.me?.allowedTransfers);
    setAllowedTransfersSnapshot(user?.me?.allowedTransfers);
    setTeamBalance(user?.me?.balance);
    setTeamBalanceSnapshot(user?.me?.balance);
  }, [user]);

  useEffect(() => {
    if (state === null || squadSnapshot === null) return;
    setAllowedTransfers(
      allowedTransfersSnapshot - getSquadDiffNumber(state, squadSnapshot)
    );
  }, [allowedTransfersSnapshot, state, squadSnapshot]);

  return (
    <SquadContext.Provider
      value={{
        squad: state,
        squadSnapshot,
        setSquadSnapshot,
        setSquad,
        squadStack,
        setSquadStack,
        action,
        setAction,
        outPlayer,
        setOutPlayer,
        inPlayer,
        setInPlayer,
        addPlayer,
        removePlayer,
        substitutePlayer,
        setCapitan,
        setViceCapitan,
        setBpr,
        selectedItem,
        setSelectedItem,
        clearAction,
        flatList,
        teamBalance,
        setTeamBalance,
        teamBalanceSnapshot,
        setTeamBalanceSnapshot,
        playersNumber,
        allowedTransfers,
        squadScores,
        setSquadScores,
        allowedTransfersSnapshot,
        setAllowedTransfersSnapshot,
        setAllowedTransfers,
      }}
    >
      {children}
    </SquadContext.Provider>
  );
}

export default SquadProvider;
