import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import styles from "./index.module.scss";
import { Button, Spinner } from "react-bootstrap";
import { useMutation } from "react-query";
import API from "../../api";
import { AuthContext } from "../../providers/AuthProvider";
import { SettingsContext } from "../../providers/SettingsProvider";
import { getTeamLogoUrl } from "../../helpers/utils";
import Select from "react-select";

const formatOptionLabel = ({ value, label, customAbbreviation }) => (
  <div className={styles.selectItem}>
    <img className={styles.logo} src={getTeamLogoUrl(value)} alt="" />
    <div className={styles.caption}>{label}</div>
  </div>
);

const FavTeam = ({ show, onHide, selectMode, onSelect, defaultTeamId }) => {
  const [currentTeam, setCurrentTeam] = useState(null);
  const [loading, setLoading] = useState(false);
  const [teamOptions, setTeamOptions] = useState([]);

  const { user, setUser } = useContext(AuthContext);
  const { teams, prevLeague } = useContext(SettingsContext);

  useEffect(() => {
    if (Object.keys(teams).length === 0) return;
    const dropdownOptions = Array.from(
      Object.keys(teams).map((teamId) => ({
        label: teams[teamId].name,
        value: +teamId,
      }))
    );
    setTeamOptions(dropdownOptions);
  }, [teams]);

  useEffect(() => {
    if (!defaultTeamId || teamOptions.length === 0) return;
    const newTeam = teamOptions.find((item) => item.value === defaultTeamId);
    setCurrentTeam(newTeam);
  }, [defaultTeamId, teamOptions]);

  const mutation = useMutation({
    mutationFn: (currentTeam) => {
      setLoading(true);
      return API.saveFavTeam(currentTeam, Boolean(prevLeague));
    },
    onSuccess: (response) => {
      const isLeagueTransfer = Boolean(prevLeague);
      if (!isLeagueTransfer) {
        window.favTeamSaved = true;
        setUser({
          ...user,
          me: {
            ...user.me,
            favTeamId: response.data.FavTeamId,
            isAllowedFavTeamUpdate: response.data.isAllowedFavTeamUpdate,
          },
        });
        setLoading(false);
        onHide();
      } else {
        window.location.reload();
      }
    },
    onError: () => {
      setLoading(false);
    },
  });

  const onTeamChange = (selectedOption) => {
    const targetTeam =
      teamOptions.find((entry) => entry.value === selectedOption.value) ?? null;
    setCurrentTeam(targetTeam);
  };

  return (
    <Modal show={show} size="md" backdrop="static" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="flex">
          <i className="bi bi-check2-circle"></i>&nbsp;
          {selectMode ? "Вибір ліги" : "Вибір улюбленого клубу"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {!selectMode && (
          <>
            <p>
              В залежності від вашого вибору ви автоматично станете учасником
              Ліги вболівальників обраного клубу.
            </p>
            <p style={{ color: "red" }}>
              <strong>
                Увага! Змінити улюблений клуб можна тільки один раз протягом
                місяця з моменту реєстрації у лізі!
              </strong>
            </p>
          </>
        )}

        <Select
          className={styles.dropdown}
          onChange={onTeamChange}
          value={currentTeam}
          options={teamOptions}
          formatOptionLabel={formatOptionLabel}
          placeholder={selectMode ? "Оберіть лігу" : "Оберіть улюблений клуб"}
        />

        <div className={styles.actions}>
          <Button
            onClick={() =>
              selectMode
                ? onSelect(currentTeam)
                : mutation.mutate(currentTeam.value)
            }
            className={styles.btn}
            disabled={
              loading ||
              defaultTeamId === Number(currentTeam?.value) ||
              currentTeam === null
            }
          >
            {loading && (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp;
              </>
            )}
            {selectMode ? "Переглянути" : "Зберегти"}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FavTeam;
