import React, { useEffect, useContext, useMemo } from "react";
import { useState } from "react";
import styles from "./index.module.scss";
import { ATTACKER, DEFENDER, GOALKEEPER, MIDFIELDER } from "../../constants";
import { AdminContext } from "../../providers/AdminProvider";
import { SettingsContext } from "../../providers/SettingsProvider";
import { SquadContext } from "../../providers/SquadProvider";
import { getPlayerById } from "../../helpers/player";
import { Badge, Spinner, Placeholder, Button } from "react-bootstrap";
import { getPlayerLastName } from "../../helpers/player";
import { getShortPosition } from "../../helpers/player";
import Image from "react-graceful-image";
import {
  getReservePosition,
  isPlayerTeamWillNotPlay,
  isActiveCapitan,
} from "../../helpers/squad";
import { getPlayerScoresInCurrentRound } from "../../helpers/squad";
import { getReserveList } from "../../helpers/squad";
import { isCapitan, isViceCapitan } from "../Squad";
import { CSSTransition } from "react-transition-group";
import { getPlayerPhotoUrl } from "../../helpers/utils";

import {
  isDefenderSelected,
  isGoalKeeperSelected,
  isMidFielderSelected,
  isAttackerSelected,
  isReserveSelected,
} from "../Squad";
import { parseScheme } from "../../helpers/scheme";
import { rndInt, getTeamLogoUrl } from "../../helpers/utils";
import { MAX_FIELD_BENCH_PLAYERS } from "../../helpers/bench";

export const getReserveIndex = (playerId, reserve = {}, position) => {
  switch (position) {
    case GOALKEEPER:
      return reserve.GK.indexOf(playerId);
    case DEFENDER:
      return reserve.D.indexOf(playerId);
    case MIDFIELDER:
      return reserve.MD.indexOf(playerId);
    case ATTACKER:
      return reserve.ST.indexOf(playerId);
    default:
      return null;
  }
};

const getPlayerRoundScores = (playerId, selectedDetails = []) => {
  const player = selectedDetails.find((item) => item.PlayerId === playerId);
  const pts = Number(player?.Pts || 0) + Number(player?.Extra || 0);

  if (player?.Calc === undefined) {
    return pts;
  }
  return player?.Calc ? pts : 0;
};

const Player = ({
  playerId,
  position,
  index,
  isReserve,
  selected,
  onItemClick,
  isClickAllowed,
  isSubstitution,
  isCapitan,
  isViceCapitan,
  isHistoricalView,
  selectedDetails,
  cap,
  vcap,
  loading,
  bpr = [],
  viewOrder,
}) => {
  const [player, setPlayer] = useState({});
  const [inTr, setInTr] = useState(false);
  const isPlayer = playerId !== null;

  const { players } = useContext(AdminContext);

  const { calculatedTeamIds, roundMatches, activeDeadline } = useContext(
    SettingsContext
  );
  const { squadScores } = useContext(SquadContext);

  useEffect(() => {
    const player = getPlayerById(players, playerId);
    setPlayer(player);

    setTimeout(() => setInTr(true), 300);
  }, [players, playerId]);

  const playerScoresInCurrentRound = getPlayerScoresInCurrentRound(
    player.id,
    squadScores
  );

  const activeCapitan = isActiveCapitan(
    player.id,
    cap,
    vcap,
    squadScores,
    selectedDetails,
    isHistoricalView
  );

  const getOverallPlayerPts = () => {
    let pts = player?.Pts ?? 0;

    if (isHistoricalView) {
      return getPlayerRoundScores(player.id || playerId, selectedDetails);
    } else if (!isReserve) {
      if (activeCapitan) {
        return pts + playerScoresInCurrentRound / 2;
      } else {
        return pts + playerScoresInCurrentRound;
      }
    } else {
      return pts + playerScoresInCurrentRound;
    }
  };

  const reservePositionLabel = () => {
    const benchIndex = bpr.indexOf(playerId);
    const priorityLabel =
      !isHistoricalView && benchIndex !== -1 ? benchIndex + 1 : "";
    return getShortPosition(position) + " " + (isPlayer ? priorityLabel : "");
  };

  const isCalcPlayer = useMemo(() => {
    if (!isHistoricalView && activeDeadline) return false;
    return !isHistoricalView
      ? squadScores.find((scoreItem) => scoreItem.playerId === playerId)?.calc
      : selectedDetails.find((item) => item.PlayerId === playerId)?.Calc;
  }, [
    playerId,
    squadScores,
    selectedDetails,
    activeDeadline,
    isHistoricalView,
  ]);

  const isPlayerIn = useMemo(() => {
    if (!isHistoricalView && activeDeadline) return false;
    const targetList = !isHistoricalView ? squadScores : selectedDetails;

    const currentPlayerIndex = targetList.findIndex(
      (item) => (isHistoricalView ? item.PlayerId : item.playerId) === playerId
    );

    return isHistoricalView
      ? targetList[currentPlayerIndex - 1]?.Replace
      : targetList[currentPlayerIndex - 1]?.replace;
  }, [
    playerId,
    squadScores,
    selectedDetails,
    activeDeadline,
    isHistoricalView,
  ]);

  const isPlayerOut = useMemo(() => {
    if (!isHistoricalView && activeDeadline) return false;
    return !isHistoricalView
      ? squadScores.find((scoreItem) => scoreItem.playerId === playerId)
          ?.replace
      : selectedDetails.find((item) => item.PlayerId === playerId)?.Replace;
  }, [
    playerId,
    squadScores,
    selectedDetails,
    activeDeadline,
    isHistoricalView,
  ]);

  if (!isHistoricalView && typeof player === "object" && !player.id) {
    return (
      <CSSTransition
        in={inTr}
        timeout={500}
        classNames="player-widget"
        unmountOnExit
      >
        <div className={styles.outCnt}>
          <div
            style={styles.playerCnt}
            className={`${styles.item} ${!isPlayer && styles.blur} ${
              selected && styles.toggled
            } ${(!isClickAllowed || playerId === 0) && styles.clickDisabled}`}
            onClick={() =>
              onItemClick({
                playerId,
                position,
                index,
                isReserve,
                isFantomPlayer: true,
              })
            }
          >
            <div className={styles.bio}>
              <div className={styles.photo}>
                <i
                  className={`bi ${
                    activeDeadline ? "bi-person-fill-add" : "bi-lock"
                  } ${styles.exclamation}`}
                ></i>
              </div>
              <div className={styles.info}>
                <div
                  className={styles.lastname}
                  style={{ position: "relative", top: -2 }}
                >
                  ВАКАНСІЯ
                </div>
                {!isReserve && (
                  <Badge className={`${styles.pos} ${styles.shortPos}`}>
                    {getShortPosition(position)}
                  </Badge>
                )}
              </div>
            </div>
          </div>
          {isReserve && (
            <Badge className={styles.pos}>{reservePositionLabel()}</Badge>
          )}{" "}
        </div>
      </CSSTransition>
    );
  }

  return (
    <CSSTransition
      in={inTr}
      timeout={500}
      classNames="player-widget"
      unmountOnExit
    >
      <div
        style={{ order: viewOrder >= 0 ? viewOrder + 1 : "unset" }}
        className={styles.outCnt}
      >
        {loading && isReserve ? (
          <Placeholder as="div" animation="glow" style={{ width: "100%" }}>
            <Placeholder xs={12} style={{ height: 105, width: "100%" }} />{" "}
          </Placeholder>
        ) : (
          <div
            style={styles.playerCnt}
            className={`${styles.item} ${!isPlayer && styles.blur} ${
              selected && styles.toggled
            } ${(!isClickAllowed || playerId === 0) && styles.clickDisabled}`}
            onClick={() =>
              onItemClick({ playerId, position, index, isReserve })
            }
          >
            <div className={styles.bio}>
              {isPlayer ? (
                <div
                  className={`${styles.playerBg} ${
                    process.env.REACT_APP_PHOTO_ENABLED
                      ? styles.playerBgColored
                      : styles.playerBgTransparent
                  }`}
                >
                  <Image
                    className={styles.photo}
                    src={getPlayerPhotoUrl(player)}
                    alt=""
                  />
                  {player.teamId && process.env.REACT_APP_PHOTO_ENABLED && (
                    <Image
                      className={styles.logo}
                      src={getTeamLogoUrl(player.teamId, rndInt())}
                      alt=""
                    />
                  )}
                  {isCapitan && (
                    <Badge
                      className={styles.cap}
                      bg={`${
                        activeCapitan || (!isHistoricalView && activeDeadline)
                          ? "danger"
                          : "dark"
                      }`}
                    >
                      К
                    </Badge>
                  )}
                  {isViceCapitan && (
                    <Badge
                      className={styles.cap}
                      bg={`${
                        activeCapitan || (!isHistoricalView && activeDeadline)
                          ? "danger"
                          : "dark"
                      }`}
                    >
                      ВК
                    </Badge>
                  )}
                </div>
              ) : (
                <>
                  {selected ? (
                    <i
                      className={`bi bi-arrow-repeat ${styles.icon} ${styles.rotate}`}
                    ></i>
                  ) : (
                    <i className={`bi bi-plus-circle-fill ${styles.icon}`}></i>
                  )}
                </>
              )}
              {isPlayer ? (
                playerId !== 0 ? (
                  <div className={styles.info}>
                    <div className={styles.lastname}>
                      {getPlayerLastName(player)}
                    </div>
                    <div className={styles.hint}>
                      {!isHistoricalView &&
                        (calculatedTeamIds.includes(player.teamId) ? (
                          isCalcPlayer && (
                            <div className={styles.tablo}>
                              {playerScoresInCurrentRound}
                            </div>
                          )
                        ) : !isHistoricalView &&
                          (isPlayerTeamWillNotPlay(
                            roundMatches,
                            player.teamId
                          ) ||
                            player.isSuspended) ? (
                          <div className={styles.tablo}>
                            <i
                              className={`bi bi-shield-fill-exclamation ${styles.miniExclamation}`}
                            ></i>{" "}
                          </div>
                        ) : (
                          !isReserve && (
                            <div className={styles.tablo}>
                              <i
                                className="bi bi-hourglass"
                                style={{ fontSize: "0.8rem" }}
                              ></i>
                            </div>
                          )
                        ))}

                      <Badge className={styles.pts} bg="success">
                        {getOverallPlayerPts()}
                      </Badge>

                      <Badge bg="warning" className={styles.cost}>
                        {player?.Cost?.toFixed(1)}{" "}
                        <i className={`bi bi-coin ${styles.coin}`} />
                      </Badge>
                    </div>

                    {isClickAllowed && isSubstitution && (
                      <i
                        className={`bi bi-arrow-repeat ${styles.position} ${styles.icon} ${styles.rotate}`}
                      ></i>
                    )}
                    {isPlayerOut && (
                      <i
                        className={`bi bi-arrow-down-circle-fill ${styles.autoreplace} ${styles.down}`}
                      ></i>
                    )}
                    {isPlayerIn && (
                      <i
                        className={`bi bi-arrow-up-circle-fill ${styles.autoreplace} ${styles.up}`}
                      ></i>
                    )}
                  </div>
                ) : null
              ) : null}
            </div>
          </div>
        )}
        {isReserve &&
          (loading ? (
            <Placeholder as="div" animation="glow">
              <Placeholder xs={12} style={{ height: 12, marginTop: 8 }} />
            </Placeholder>
          ) : (
            <Badge className={styles.pos}>{reservePositionLabel()}</Badge>
          ))}
      </div>
    </CSSTransition>
  );
};

const FootballField = ({
  attackersRef,
  defendersRef,
  midfieldersRef,
  benchRef,
  goalkeeperRef,
  squad,
  onItemClick,
  isGoalkeeperClickAllowed,
  isDefenderClickAllowed,
  isMidfielderClickAllowed,
  isAttackerClickAllowed,
  action,
  selectedItem,
  outPlayer,
  isHistoricalView,
  isBestPlayersView,
  selectedDetails,
  inactive,
  loading,
  showBenchPrioritiesModal,
}) => {
  const { activeDeadline } = useContext(SettingsContext);

  const isFullBench = useMemo(
    () => squad?.bpr?.length === MAX_FIELD_BENCH_PLAYERS,
    [squad]
  );

  return (
    <>
      <div
        id="ftb-field"
        className={`${styles.footballFieldCnt} ${
          inactive ? styles.inactive : null
        } `}
      >
        <div className={`${styles.field} ${loading ? styles.loading : null}`}>
          <div className={styles.square}></div>
          <div className={styles.circle}></div>
          <div className={styles.corner} style={{ left: -15 }}></div>
          <div className={styles.corner} style={{ right: -15 }}></div>
          {loading ? (
            <Spinner animation="grow" />
          ) : (
            selectedDetails !== null &&
            squad.main && (
              <>
                <div
                  ref={goalkeeperRef}
                  id="goalkeepers"
                  className={styles.goalkeeper}
                >
                  {squad.main["GK"].map((player, index) => (
                    <Player
                      key={index}
                      playerId={player}
                      position={GOALKEEPER}
                      index={index}
                      isReserve={false}
                      selected={isGoalKeeperSelected(selectedItem, index)}
                      onItemClick={onItemClick}
                      isClickAllowed={isGoalkeeperClickAllowed}
                      isSubstitution={action === "substitute"}
                      isCapitan={isCapitan(squad, player)}
                      isViceCapitan={isViceCapitan(squad, player)}
                      isHistoricalView={isHistoricalView}
                      selectedDetails={selectedDetails}
                      cap={squad?.cap}
                      vcap={squad?.vcap}
                    />
                  ))}
                </div>
                <div
                  ref={defendersRef}
                  id="defenders"
                  className={styles.defenders}
                >
                  {squad.main["D"].map((player, index) => (
                    <Player
                      key={index}
                      playerId={player}
                      position={DEFENDER}
                      index={index}
                      isReserve={false}
                      selected={isDefenderSelected(selectedItem, index)}
                      onItemClick={onItemClick}
                      isClickAllowed={isDefenderClickAllowed}
                      isSubstitution={action === "substitute"}
                      isCapitan={isCapitan(squad, player)}
                      isViceCapitan={isViceCapitan(squad, player)}
                      isHistoricalView={isHistoricalView}
                      selectedDetails={selectedDetails}
                      cap={squad?.cap}
                      vcap={squad?.vcap}
                    />
                  ))}
                </div>
                <div
                  ref={midfieldersRef}
                  id="midfielders"
                  className={styles.midfielders}
                >
                  {squad.main["MD"].map((player, index) => (
                    <Player
                      key={index}
                      playerId={player}
                      position={MIDFIELDER}
                      index={index}
                      isReserve={false}
                      selected={isMidFielderSelected(selectedItem, index)}
                      onItemClick={onItemClick}
                      isClickAllowed={isMidfielderClickAllowed}
                      isSubstitution={action === "substitute"}
                      isCapitan={isCapitan(squad, player)}
                      isViceCapitan={isViceCapitan(squad, player)}
                      isHistoricalView={isHistoricalView}
                      selectedDetails={selectedDetails}
                      cap={squad?.cap}
                      vcap={squad?.vcap}
                    />
                  ))}
                </div>
                <div
                  ref={attackersRef}
                  id="attackers"
                  className={styles.attackers}
                >
                  {squad.main["ST"].map((player, index) => (
                    <Player
                      key={index}
                      playerId={player}
                      position={ATTACKER}
                      index={index}
                      isReserve={false}
                      selected={isAttackerSelected(selectedItem, index - 1)}
                      onItemClick={onItemClick}
                      isClickAllowed={isAttackerClickAllowed}
                      isSubstitution={action === "substitute"}
                      isCapitan={isCapitan(squad, player)}
                      isViceCapitan={isViceCapitan(squad, player)}
                      isHistoricalView={isHistoricalView}
                      selectedDetails={selectedDetails}
                      cap={squad?.cap}
                      vcap={squad?.vcap}
                    />
                  ))}
                </div>
              </>
            )
          )}
        </div>
      </div>
      <h5 className={styles.flex}>
        <span>
          <i className={`bi bi-person-fill`}></i>&nbsp;Запасні
        </span>
        {activeDeadline ? (
          !isHistoricalView &&
          isFullBench && (
            <Button
              className={styles.bprBtn}
              variant="outline-secondary"
              type="small"
              onClick={showBenchPrioritiesModal}
            >
              <i className={`bi bi-gear-fill ${styles.bprIcon}`}></i>
              &nbsp;Пріоритети
            </Button>
          )
        ) : (
          <span className={styles.substHint}>
            Автозаміни відбуваються після останнього матчу туру
          </span>
        )}
      </h5>
      <div
        id="bench"
        ref={benchRef}
        className={`${styles.reserved} ${inactive ? styles.inactive : null}`}
      >
        {squad?.reserve &&
          getReserveList(squad)?.map((player, index) => {
            const scheme = parseScheme(squad);
            const position = getReservePosition(scheme, index);
            const playerIndex = getReserveIndex(
              player,
              squad?.reserve,
              position
            );
            return (
              <Player
                viewOrder={squad?.bpr?.indexOf(player)}
                isHistoricalView={isHistoricalView}
                selectedDetails={selectedDetails}
                key={index}
                playerId={player}
                position={position}
                index={playerIndex}
                isReserve={true}
                selected={isReserveSelected(
                  selectedItem,
                  playerIndex,
                  position
                )}
                onItemClick={onItemClick}
                isClickAllowed={
                  !action ||
                  (action === "substitute" &&
                    outPlayer.position === position &&
                    !getReserveList(squad)
                      .filter((pl) => pl !== player)
                      .includes(outPlayer.playerId))
                }
                isSubstitution={action === "substitute"}
                isCapitan={isCapitan(squad, player)}
                loading={loading}
                bpr={squad?.bpr}
              />
            );
          })}
      </div>
    </>
  );
};

export default FootballField;
