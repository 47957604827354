export const patreonUrl = process.env.REACT_APP_PATREON_URL;
export const youtubeUrl = process.env.REACT_APP_YOUTUBE_URL;

export const getMainLeagueTeams = (teams) => {
  return Array.from(Object.keys(teams)).filter((id) => teams[id].mainLeague);
};

export const getTeamDropdownItems = (teams) => {
  return Array.from(
    Object.entries(teams).map(([key, value]) => ({
      value: +key,
      label: value.name,
    }))
  );
};

export const ALL_POSITIONS = "All";
export const GOALKEEPER = "Goalkeeper";
export const DEFENDER = "Defender";
export const MIDFIELDER = "Midfielder";
export const ATTACKER = "Attacker";

export const positionKeysMap = {
  [GOALKEEPER]: "GK",
  [DEFENDER]: "D",
  [MIDFIELDER]: "MD",
  [ATTACKER]: "ST",
};

export const TRANSFERS_THRESHOLD = 100;

export const ALL_MONEY = 100;
export const ALL_PLAYERS = 15;
export const ALL_DEFENDERS = 5;
export const ALL_MIDFIELDERS = 5;
export const ALL_ATTACKERS = 3;
export const tgLink = process.env.REACT_APP_TELEGRAM_CHANNEL_LINK;
export const tgChatLink = process.env.REACT_APP_TELEGRAM_CHAT_LINK;
