import React, { useCallback, useContext, useState, useMemo } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Grid, Row, Col } from "react-flexbox-grid";
import styles from "./index.module.scss";
import Select from "react-select";
import { getTeamDropdownItems } from "../../constants";
import { SettingsContext } from "../../providers/SettingsProvider";
import { GOALKEEPER, DEFENDER, MIDFIELDER, ATTACKER } from "../../constants";
import { useMutation } from "react-query";
import API from "../../api";
import { AdminContext } from "../../providers/AdminProvider";

const playerPositions = [
  {
    value: GOALKEEPER,
    label: "Голкіпер",
  },
  {
    value: DEFENDER,
    label: "Захисник",
  },
  {
    value: MIDFIELDER,
    label: "Півзахисник",
  },
  {
    value: ATTACKER,
    label: "Нападник",
  },
];

const NewPlayer = ({ show, onHide }) => {
  const { teams: teamList } = useContext(SettingsContext);
  const [id, setId] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [name, setName] = useState(null);
  const [position, setPosition] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [number, setNumber] = useState(null);
  const [age, setAge] = useState(null);

  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const { players, setPlayers, filteredPlayers, setFilteredPlayers } =
    useContext(AdminContext);

  const teamsOptions = useMemo(
    () => getTeamDropdownItems(teamList),
    [teamList]
  );

  const mutation = useMutation({
    mutationFn: (playerInfo) => {
      setError("");
      setSubmitting(true);
      return API.savePlayerInfo(playerInfo);
    },
    onSuccess: (response, variables, context) => {
      const newPlayers = [...players, variables];
      const newFilteredPlayers = [...filteredPlayers, variables];
      setPlayers(newPlayers);
      setFilteredPlayers(newFilteredPlayers);
      setSubmitting(false);
      onHide();
    },
    onError: (e) => {
      setError(e?.response?.data?.error);
      setSubmitting(false);
    },
  });

  const onChangeTeam = useCallback(({ value }) => {
    setTeamId(value);
  }, []);

  const onChangePlayerName = useCallback((event) => {
    setName(event.target.value);
  }, []);

  const onChangePosition = useCallback(({ value }) => {
    setPosition(value);
  }, []);

  const onChangeId = useCallback((event) => {
    setId(+event.target.value);
  }, []);

  const onChangePhotoUrl = useCallback((event) => {
    setPhoto(event.target.value);
  }, []);

  const onChangeAge = useCallback((event) => {
    setAge(+event.target.value);
  }, []);

  const onChangeNumber = useCallback((event) => {
    setNumber(+event.target.value);
  }, []);

  const isSubmitDisabled =
    !number ||
    !age ||
    !photo ||
    !id ||
    !position ||
    !name ||
    !teamId ||
    submitting;

  return (
    <Modal show={show} size="lg" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="flex">
          <i className="bi bi-plus-circle"></i>&nbsp;Додати футболіста
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ minHeight: 250 }}>
        <Grid>
          <Row>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3">
                <Form.Label>ID</Form.Label>
                <Form.Control
                  style={{ fontSize: "1.3rem" }}
                  type="text"
                  placeholder="123456"
                  onChange={onChangeId}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Команда</Form.Label>
                <Select
                  className={styles.dropdown}
                  options={teamsOptions}
                  onChange={onChangeTeam}
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Ім'я</Form.Label>
                <Form.Control
                  style={{ fontSize: "1.3rem" }}
                  type="text"
                  placeholder="Ім'я"
                  onChange={onChangePlayerName}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Group className="mb-3">
                <Form.Label>Лінк на фото</Form.Label>
                <Form.Control
                  style={{ fontSize: "1.3rem" }}
                  type="text"
                  placeholder="Фото"
                  onChange={onChangePhotoUrl}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Ігровий номер</Form.Label>
                <Form.Control
                  style={{ fontSize: "1.3rem" }}
                  type="text"
                  placeholder="Номер"
                  onChange={onChangeNumber}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Вік</Form.Label>
                <Form.Control
                  style={{ fontSize: "1.3rem" }}
                  type="text"
                  placeholder="Вік"
                  onChange={onChangeAge}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Позиція</Form.Label>
                <Select
                  className={styles.dropdown}
                  options={playerPositions}
                  onChange={onChangePosition}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className={styles.error}>{error}</div>
        </Grid>
        <div className={styles.actions}>
          <Button
            disabled={isSubmitDisabled}
            onClick={() =>
              mutation.mutate({
                id,
                name,
                teamId,
                photo,
                age,
                number,
                position,
              })
            }
          >
            Зберегти
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(NewPlayer);
