import React, { useContext, useState } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import { SquadContext } from "../../providers/SquadProvider";
import { SettingsContext } from "../../providers/SettingsProvider";
import styles from "./index.module.scss";
import { getSplittedName } from "../../helpers/user";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { isSquadReadyToPlay } from "../../helpers/squad";
import { getTeamLogoUrl, rndInt } from "../../helpers/utils";
import RevertSquad from "../../modals/RevertSquad";
import { TRANSFERS_THRESHOLD } from "../../constants";

const Coach = ({
  onToggle,
  showFavTeamModal,
  defaultViewToggle,
  isHistoricalRound,
}) => {
  const [revertSquadModal, setRevertSquadModal] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { flatList, playersNumber, squad, allowedTransfers } = useContext(
    SquadContext
  );
  const { activeDeadline } = useContext(SettingsContext);

  const isFullSquad = isSquadReadyToPlay(flatList, playersNumber, squad);

  const favTeamId = user?.me?.favTeamId;
  const isAllowedFavTeamUpdate = user?.me?.isAllowedFavTeamUpdate;

  const showRevertSquadModel = () => {
    setRevertSquadModal(true);
  };

  const onHideRevertSquadModel = () => {
    setRevertSquadModal(false);
  };

  const isActiveRevertSquadBtn =
    activeDeadline &&
    user?.me?.canRevertSquad &&
    allowedTransfers < TRANSFERS_THRESHOLD;

  return (
    <div className={styles.coach}>
      <div className={styles.leftCnt}>
        {
          <div
            className={`${styles.favCnt} ${
              !isAllowedFavTeamUpdate && styles.disabled
            }`}
            onClick={showFavTeamModal}
          >
            <div className={styles.favCaption}>Клуб</div>
            {favTeamId ? (
              <img
                className={styles.teamImg}
                src={getTeamLogoUrl(favTeamId, rndInt())}
                alt=""
              />
            ) : (
              <span className={styles.qst}>?</span>
            )}
          </div>
        }
        <div
          className={styles.person}
          onClick={() => navigate(`/details/${user?.me?.userId}`)}
        >
          <div style={{ position: "relative" }}>
            <img
              className={styles.logo}
              src={user?.me?.logo}
              alt=""
              referrerPolicy="no-referrer"
            />
            {isFullSquad ? (
              <i
                className={`bi bi-check-circle-fill ${styles.successStatus}`}
              ></i>
            ) : (
              <i className={`bi bi-x-circle-fill ${styles.errorStatus}`}></i>
            )}
          </div>
          <div className={styles.userName}>
            {getSplittedName(user?.me?.fullName)}
          </div>
        </div>
      </div>
      <div className={styles.rightCnt}>
        {isActiveRevertSquadBtn && (
          <div className={styles.revertCnt}>
            <div className={styles.caption}>
              Скинути
              <br />
              склад
            </div>
            <button
              className={styles.revertBtn}
              onClick={() => showRevertSquadModel()}
            >
              <i
                style={{ display: "flex", justifyContent: "center" }}
                className="bi bi-trash"
              ></i>
            </button>{" "}
          </div>
        )}
        <div
          className={styles.toggleContainer}
          style={{ visibility: isHistoricalRound ? "hidden" : "visible" }}
        >
          <div className={styles.hint}>
            Вид
            <br />
            складу
          </div>
          <Form.Check // prettier-ignore
            type="switch"
            id="custom-switch"
            className={styles.toggle}
            onChange={(e) => onToggle(e.target.checked)}
            defaultChecked={defaultViewToggle}
          />
        </div>
      </div>
      {isActiveRevertSquadBtn && (
        <RevertSquad show={revertSquadModal} onHide={onHideRevertSquadModel} />
      )}
    </div>
  );
};

export default Coach;
