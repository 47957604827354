import React, { useContext } from "react";
import styles from "./index.module.scss";
import { Badge } from "react-bootstrap";
import { getSplittedName, isMe } from "../../helpers/user";
import { AuthContext } from "../../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import Message from "../Message";
import { getTeamLogoUrl, rndInt } from "../../helpers/utils";
import Image from "react-graceful-image";
import { WindowScroller, List } from "react-virtualized";
import LiveStatus from "../LiveStatus";

const StandingsView = ({ items, marginTop }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
  }) {
    const item = items[index];
    return (
      <div
        style={style}
        key={key}
        data-user-id={item.UserId}
        className={styles.listItem}
        onClick={() => navigate(`/details/${item.UserId}`)}
      >
        <div className={styles.flexItem}>
          <Badge className={styles.rank} bg="success">
            {item.Rank}
          </Badge>
          <div className={styles.person}>
            <Image className={styles.logo} src={item.Logo} alt="" />
            <div className={styles.caption}>
              {getSplittedName(item.FullName)}
            </div>
            {isMe(user?.me, item.UserId) && (
              <i className={`${styles.meIcon} bi bi-star-fill`}></i>
            )}
          </div>
        </div>
        <div className={`${styles.flexItem} fav-team-column`}>
          {item.FavTeamId ? (
            <>
              <i className="bi bi-suit-heart"></i>&nbsp;
              <Image
                style={{ height: 30, filter: "sepia(1)" }}
                src={getTeamLogoUrl(item.FavTeamId, rndInt())}
                alt=""
              />
            </>
          ) : null}
        </div>
        <div className={styles.flexItem}>{item.Games}</div>
        <div className={styles.flexItem}>
          {item.CalcNumber !== undefined && (
            <LiveStatus all={11} passed={item.CalcNumber} />
          )}
        </div>
        <div style={{ fontWeight: "bold" }} className={styles.flexItem}>
          {item.Points}
        </div>
      </div>
    );
  }

  return (
    <WindowScroller>
      {({ height, isScrolling, registerChild, scrollTop }) => (
        <div>
          <div ref={registerChild}>
            <List
              autoHeight
              isScrolling={isScrolling}
              rowCount={items.length}
              rowHeight={45}
              scrollTop={scrollTop}
              width={1}
              height={height}
              rowRenderer={rowRenderer}
              containerStyle={{ width: "100%", maxWidth: "100%" }}
              style={{ width: "100%", marginTop: marginTop ?? 54 }}
              noRowsRenderer={() => (
                <div className={styles.noRows}>
                  <Message text="Інформація поки що недоступна" />
                </div>
              )}
            />
          </div>
        </div>
      )}
    </WindowScroller>
  );
};

export default StandingsView;
