import axios from "axios";

export const baseUrl = process.env.REACT_APP_API_BASE_URL;
export const baseAuthUrl = process.env.REACT_APP_BASE_AUTH_URL;

export const getAuthorization = () =>
  `Bearer ${localStorage.getItem("access_token")}`;
export const getAuthType = () => localStorage.getItem("authType");
export const getRefreshToken = () => localStorage.getItem("refresh_token");

const API = {
  fetchPlayers: () => {
    return axios.get(`${baseUrl}/players?authType=${getAuthType()}`, {
      headers: {
        Authorization: getAuthorization(),
      },
    });
  },
  fetchSquad: (me) => {
    return axios.get(
      `${baseUrl}/squad?authType=${getAuthType()}&me=${encodeURIComponent(me)}`,
      {
        headers: {
          Authorization: getAuthorization(),
        },
      }
    );
  },
  fetchSettings: () => {
    return axios.get(`${baseUrl}/settings?authType=${getAuthType()}`, {
      headers: {
        Authorization: getAuthorization(),
      },
    });
  },
  fetchPlayerScoresByRound: (round) => {
    return axios.get(
      `${baseUrl}/scores?authType=${getAuthType()}&round=${round}`,
      {
        headers: {
          Authorization: getAuthorization(),
        },
      }
    );
  },
  fetchPreview: (matchId, calculated) => {
    return axios.get(
      `${baseUrl}/preview?matchId=${matchId}&calculated=${calculated}&authType=${getAuthType()}`,
      {
        headers: {
          Authorization: getAuthorization(),
        },
      }
    );
  },
  fetchRoundInfo: (round) => {
    return axios.get(
      `${baseUrl}/info?round=${round}&authType=${getAuthType()}`,
      {
        headers: {
          Authorization: getAuthorization(),
        },
      }
    );
  },
  fetchRoundMatches: (round) => {
    return axios.get(
      `${baseUrl}/rounds?round=${round}&authType=${getAuthType()}`,
      {
        headers: {
          Authorization: getAuthorization(),
        },
      }
    );
  },
  fetchHistory: (round) => {
    return axios.get(
      `${baseUrl}/history?round=${round}&authType=${getAuthType()}`,
      {
        headers: {
          Authorization: getAuthorization(),
        },
      }
    );
  },
  fetchStandings: (round = null, topNumber = 10000, me, leagueId) => {
    const roundQuery = round ? `&round=${round}` : "";
    const leagueQuery = leagueId ? `&leagueId=${leagueId}` : "";
    return axios.get(
      `${baseUrl}/standings?authType=${getAuthType()}${roundQuery}&topNumber=${topNumber}&me=${encodeURIComponent(
        me
      )}${leagueQuery}`,
      {
        headers: {
          Authorization: getAuthorization(),
        },
      }
    );
  },
  fetchLeague: ({ leagueId, members = [], filter }) => {
    let url = `${baseUrl}/leagues?authType=${getAuthType()}`;
    if (leagueId !== undefined) {
      url += `&leagueId=${leagueId}`;
    }

    if (members.length > 0) {
      url += `&members=${encodeURIComponent(members.join(","))}`;
    }

    if (filter) {
      url += `&filter=${filter}`;
    }

    return axios.get(url, {
      headers: {
        Authorization: getAuthorization(),
      },
    });
  },
  fetchUserDetails: (playerId) => {
    return axios.get(
      `${baseUrl}/users?authType=${getAuthType()}&userId=${encodeURIComponent(
        playerId
      )}`,
      {
        headers: {
          Authorization: getAuthorization(),
        },
      }
    );
  },
  fetchPlayerStats: (playerId, round = 0) => {
    return axios.get(
      `${baseUrl}/stats?authType=${getAuthType()}&playerId=${playerId}&round=${round}`,
      {
        headers: {
          Authorization: getAuthorization(),
        },
      }
    );
  },
  fetchSponsors: () => {
    return axios.get(`${baseUrl}/sponsors?authType=${getAuthType()}`, {
      headers: {
        Authorization: getAuthorization(),
      },
    });
  },
  savePlayerInfo: (playerInfo) => {
    return axios.post(
      `${baseUrl}/players?authType=${getAuthType()}`,
      playerInfo,
      {
        headers: {
          Authorization: getAuthorization(),
        },
      }
    );
  },
  saveSquad: (squad) => {
    return axios.post(`${baseUrl}/squad?authType=${getAuthType()}`, squad, {
      headers: {
        Authorization: getAuthorization(),
      },
    });
  },
  revertSquad: () => {
    return axios.patch(`${baseUrl}/squad?authType=${getAuthType()}`, null, {
      headers: {
        Authorization: getAuthorization(),
      },
    });
  },
  saveSettings: (body) => {
    return axios.post(`${baseUrl}/settings?authType=${getAuthType()}`, body, {
      headers: {
        Authorization: getAuthorization(),
      },
    });
  },
  calcMatches: (body, round) => {
    return axios.post(
      `${baseUrl}/calc?round=${round}&authType=${getAuthType()}`,
      body,
      {
        timeout: 60000,
        headers: {
          Authorization: getAuthorization(),
        },
      }
    );
  },
  closeRound: () => {
    return axios.put(`${baseUrl}/calc?authType=${getAuthType()}`, null, {
      timeout: 60000,
      headers: {
        Authorization: getAuthorization(),
      },
    });
  },
  savePrevRanks: () => {
    return axios.put(`${baseUrl}/standings?authType=${getAuthType()}`, null, {
      timeout: 60000,
      headers: {
        Authorization: getAuthorization(),
      },
    });
  },
  generateStandings: () => {
    return axios.post(`${baseUrl}/standings?authType=${getAuthType()}`, null, {
      timeout: 60000,
      headers: {
        Authorization: getAuthorization(),
      },
    });
  },
  createRound: () => {
    return axios.post(`${baseUrl}/rounds?authType=${getAuthType()}`, null, {
      timeout: 60000,
      headers: {
        Authorization: getAuthorization(),
      },
    });
  },
  editFixture: (action, payload, currentRound) => {
    return axios.put(
      `${baseUrl}/rounds?authType=${getAuthType()}`,
      {
        action,
        match: payload,
        currentRound,
      },
      {
        timeout: 60000,
        headers: {
          Authorization: getAuthorization(),
        },
      }
    );
  },
  saveFavTeam: (teamId, leagueTransfer) => {
    return axios.put(
      `${baseUrl}/users?authType=${getAuthType()}`,
      { FavTeamId: teamId, leagueTransfer },
      {
        headers: {
          Authorization: getAuthorization(),
        },
      }
    );
  },
  fetchUserInfo: (code) => {
    const queryString = code
      ? `code=${encodeURIComponent(code)}&redirect_uri=${encodeURIComponent(
          window.location.origin + "/login"
        )}`
      : "";
    return axios.get(
      `${baseUrl}/auth?${queryString}&authType=${getAuthType()}`,
      {
        headers: {
          Authorization: code ? "" : getAuthorization(),
        },
      }
    );
  },
  refreshAuthToken: () => {
    return axios.post(`${baseUrl}/auth`, {
      authType: getAuthType(),
      refreshToken: getRefreshToken(),
    });
  },
  createLeague: (body) => {
    return axios.post(
      `${baseUrl}/private-leagues?authType=${getAuthType()}`,
      body,
      {
        headers: {
          Authorization: getAuthorization(),
        },
      }
    );
  },
  fetchPrivateLeagues: (leagueId) => {
    let url = `${baseUrl}/private-leagues?authType=${getAuthType()}`;
    if (leagueId) {
      url += `&leagueId=${leagueId}`;
    }
    return axios.get(url, {
      headers: {
        Authorization: getAuthorization(),
      },
    });
  },
  deletePrivateLeague: (leagueId) => {
    return axios.delete(
      `${baseUrl}/private-leagues?authType=${getAuthType()}&leagueId=${leagueId}`,
      {
        headers: {
          Authorization: getAuthorization(),
        },
      }
    );
  },
  updatePrivateLeague: (body) => {
    return axios.put(
      `${baseUrl}/private-leagues?authType=${getAuthType()}`,
      body,
      {
        headers: {
          Authorization: getAuthorization(),
        },
      }
    );
  },
};

export default API;
