import React, { useContext, useEffect, useState, useMemo } from "react";
import styles from "./item.module.scss";
import { AdminContext } from "../../providers/AdminProvider";
import { SquadContext } from "../../providers/SquadProvider";
import { SettingsContext } from "../../providers/SettingsProvider";
import {
  getPlayerById,
  getPlayerLastName,
  getPlayerShortTeam,
  getShortPosition,
} from "../../helpers/player";
import { Badge } from "react-bootstrap";
import {
  getPlayerScoresInCurrentRound,
  isPlayerTeamWillNotPlay,
  isActiveCapitan,
} from "../../helpers/squad";
import { getPlayerPhotoUrl } from "../../helpers/utils";
import { GOALKEEPER } from "../../constants";
import Image from "react-graceful-image";

const SquadItem = ({
  playerId,
  position,
  index,
  isReserve,
  onItemClick,
  selected,
  isClickAllowed,
  isSubstitution,
  isCapitan,
  isViceCapitan,
  cap,
  vcap,
  bpr,
  viewOrder,
}) => {
  const [player, setPlayer] = useState({});
  const isPlayer = playerId !== null;

  const { players } = useContext(AdminContext);

  const { calculatedTeamIds, roundMatches, activeDeadline, teams } = useContext(
    SettingsContext
  );
  const { squadScores } = useContext(SquadContext);

  const isFirstRow = index === 0 && position === GOALKEEPER && !isReserve;

  useEffect(() => {
    const player = getPlayerById(players, playerId);
    setPlayer(player);
  }, [players, playerId]);

  const isCalcPlayer = useMemo(() => {
    if (activeDeadline) return false;
    return squadScores.find((scoreItem) => scoreItem.playerId === playerId)
      ?.calc;
  }, [playerId, squadScores, activeDeadline]);

  const playerScoresInCurrentRound = getPlayerScoresInCurrentRound(
    player.id,
    squadScores
  );
  const reservePositionLabel = () => {
    const benchIndex = bpr.indexOf(playerId);
    const priorityLabel = benchIndex !== -1 ? benchIndex + 1 : "";
    return getShortPosition(position) + " " + (isPlayer ? priorityLabel : "");
  };

  const activeCapitan = isActiveCapitan(player.id, cap, vcap, squadScores);

  const getOverallPlayerPts = () => {
    let pts = player?.Pts ?? 0;

    if (!isReserve) {
      if (activeCapitan) {
        return pts + playerScoresInCurrentRound / 2;
      } else {
        return pts + playerScoresInCurrentRound;
      }
    } else {
      return pts + playerScoresInCurrentRound;
    }
  };

  const isPlayerOut = useMemo(() => {
    if (activeDeadline) return false;
    return squadScores.find((scoreItem) => scoreItem.playerId === playerId)
      ?.replace;
  }, [playerId, squadScores, activeDeadline]);

  const isPlayerIn = useMemo(() => {
    if (activeDeadline) return false;

    const currentPlayerIndex = squadScores.findIndex(
      (item) => item.playerId === playerId
    );

    return squadScores[currentPlayerIndex - 1]?.replace;
  }, [playerId, squadScores, activeDeadline]);

  if (typeof player === "object" && !player.id) {
    return (
      <div
        className={`${styles.row} ${!isPlayer && styles.blur} ${
          selected && styles.toggled
        } ${!isClickAllowed && styles.clickDisabled}`}
        onClick={() =>
          onItemClick({
            playerId,
            position,
            index,
            isReserve,
            isFantomPlayer: true,
          })
        }
      >
        <div className={styles.bio} style={{ position: "relative", left: 1 }}>
          <div className={styles.photo}>
            <i
              className={`bi ${
                activeDeadline ? "bi-person-fill-add" : "bi-lock"
              }`}
              style={{ fontSize: 35 }}
            ></i>
          </div>
          <div className={styles.info} style={{ marginLeft: 14 }}>
            ВІДКРИТА ВАКАНСІЯ
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ order: viewOrder >= 0 ? viewOrder : "" }}
      className={`${styles.row} ${!isPlayer && styles.blur} ${
        selected && styles.toggled
      } ${!isClickAllowed && styles.clickDisabled}`}
      onClick={() => onItemClick({ playerId, position, index, isReserve })}
    >
      <div className={styles.bio}>
        {isPlayer ? (
          <div style={{ position: "relative", marginRight: 4 }}>
            <Image
              className={styles.photo}
              src={getPlayerPhotoUrl(player)}
              alt=""
            />
            {isCapitan && (
              <Badge
                className={styles.cap}
                bg={`${activeCapitan || activeDeadline ? "danger" : "dark"}`}
              >
                К
              </Badge>
            )}
            {isViceCapitan && (
              <Badge
                className={styles.cap}
                bg={`${activeCapitan || activeDeadline ? "danger" : "dark"}`}
              >
                ВК
              </Badge>
            )}
          </div>
        ) : (
          <>
            {selected ? (
              <i
                className={`bi bi-arrow-repeat ${styles.icon} ${styles.rotate}`}
              ></i>
            ) : (
              <i className={`bi bi-plus-circle-fill ${styles.icon}`}></i>
            )}
          </>
        )}
        {isPlayer ? (
          <div className={styles.info}>
            <div className={styles.lastname}>{getPlayerLastName(player)}</div>
            <div className={styles.hint}>
              {getPlayerShortTeam(teams, player)} |{" "}
              {isReserve
                ? reservePositionLabel()
                : getShortPosition(player?.position)}
            </div>
            {isClickAllowed && isSubstitution && (
              <i
                className={`bi bi-arrow-repeat ${styles.position} ${styles.icon} ${styles.rotate}`}
              ></i>
            )}
            {isPlayerOut && (
              <i
                className={`bi bi-arrow-down-circle-fill ${styles.autoreplace} ${styles.down}`}
              ></i>
            )}
            {isPlayerIn && (
              <i
                className={`bi bi-arrow-up-circle-fill ${styles.autoreplace} ${styles.up}`}
              ></i>
            )}
          </div>
        ) : null}
      </div>
      <div className={styles.column}>
        {isFirstRow && <span className={styles.caption}>Ціна</span>}
        {player?.Cost ? (
          <>
            {player?.Cost?.toFixed(1)}&nbsp;
            <i
              className="bi bi-coin"
              style={{ position: "relative", top: 1 }}
            ></i>
          </>
        ) : (
          "-"
        )}
      </div>
      <div className={styles.column}>
        {isFirstRow && <span className={styles.caption}>За тур</span>}
        {isCalcPlayer && calculatedTeamIds.includes(player.teamId) ? (
          <Badge
            style={{ width: 38 }}
            bg="warning"
            className={`darkText ${styles.pts}`}
          >
            {getPlayerScoresInCurrentRound(player.id, squadScores)}
          </Badge>
        ) : isPlayerTeamWillNotPlay(roundMatches, player.teamId) ||
          player.isSuspended ? (
          <i
            className="bi bi-shield-fill-exclamation"
            style={{ color: "#bf1e2c" }}
          ></i>
        ) : (
          isCalcPlayer && (
            <Badge
              style={{ width: 38 }}
              bg="warning"
              className={`darkText ${styles.pts}`}
            >
              <i className="bi bi-hourglass"></i>
            </Badge>
          )
        )}
      </div>
      <div className={styles.column}>
        {isFirstRow && <span className={styles.caption}>За сезон</span>}
        <Badge style={{ width: 34 }} bg="success" className={styles.pts}>
          {getOverallPlayerPts()}
        </Badge>
      </div>
    </div>
  );
};

export default SquadItem;
