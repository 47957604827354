import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import styles from "./index.module.scss";
import { Button } from "react-bootstrap";
import { SquadContext } from "../../providers/SquadProvider";
import { AdminContext } from "../../providers/AdminProvider";
import { SettingsContext } from "../../providers/SettingsProvider";
import Image from "react-graceful-image";
import { getPlayerShortTeam, getShortPosition } from "../../helpers/player";
import { getPlayerPhotoUrl } from "../../helpers/utils";

const swapElements = (array, index1, index2) => {
  let temp = array[index1];
  array[index1] = array[index2];
  array[index2] = temp;
};

const BenchPrioritiesModal = ({ show, onHide }) => {
  const { squad, setBpr } = useContext(SquadContext);
  const { players } = useContext(AdminContext);
  const { teams } = useContext(SettingsContext);
  const [listItems, setListItems] = useState([]);

  useEffect(() => {
    if (show) {
      const listItems = squad.bpr.map((item) =>
        players.find((pl) => pl.id === item)
      );
      setListItems(listItems);
    }
  }, [show, players, squad]);

  const onBtnClick = (from, to) => {
    const newListItems = [...listItems];
    swapElements(newListItems, from, to);
    setListItems(newListItems);
  };

  const getControls = (index) => {
    if (index === 0) {
      return (
        <button
          className={`${styles.arrowBtn} ${styles.down}`}
          onClick={(e) => onBtnClick(index, index + 1)}
        >
          <i className="bi bi-caret-down-fill"></i>
        </button>
      );
    } else if (index === 1) {
      return (
        <>
          {" "}
          <button
            className={`${styles.arrowBtn} ${styles.down}`}
            onClick={(e) => onBtnClick(index, index + 1)}
          >
            <i className="bi bi-caret-down-fill"></i>
          </button>
          <button
            className={`${styles.arrowBtn} ${styles.up}`}
            onClick={(e) => onBtnClick(index, index - 1)}
          >
            <i className="bi bi-caret-up-fill"></i>
          </button>
        </>
      );
    } else if (index === 2) {
      return (
        <button
          className={`${styles.arrowBtn} ${styles.up}`}
          onClick={(e) => onBtnClick(index, index - 1)}
        >
          <i className="bi bi-caret-up-fill"></i>
        </button>
      );
    }
  };

  const onHideModal = () => {
    setBpr(listItems.map((item) => item.id));
    onHide();
  };

  return (
    <Modal show={show} size="md" backdrop="static" onHide={onHideModal}>
      <Modal.Header closeButton>
        <Modal.Title className="flex">
          <i className="bi bi-gear"></i>&nbsp;Змінити пріоритети
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Найвищий пріоритет на лаві запасних має футболіст під номером 1.</p>
        {listItems.map((player, index) => (
          <div key={player.id} className={styles.item}>
            <div className={styles.innerCnt}>
              <div className={styles.box}>{index + 1}</div>
              <div>
                <Image
                  className={styles.photo}
                  src={getPlayerPhotoUrl(player)}
                  alt=""
                />
              </div>
              <div className={styles.info}>
                <div className={styles.lastname}>
                  <strong>{player.LastName}</strong>
                </div>
                <div className={styles.hint}>
                  {getPlayerShortTeam(teams, player)} |{" "}
                  {getShortPosition(player?.position)}
                </div>
              </div>
            </div>
            <div>{getControls(index)}</div>
          </div>
        ))}
        <div className={styles.actions}>
          <Button onClick={onHideModal} className={styles.btn}>
            Продовжити&nbsp;<i className="bi bi-arrow-right"></i>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BenchPrioritiesModal;
