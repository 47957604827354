import { Dropdown } from "react-bootstrap";
import { useState, useContext, useEffect } from "react";
import { SquadContext } from "../../providers/SquadProvider";
import { AdminContext } from "../../providers/AdminProvider";
import {
  getSquadAccordingToNewScheme,
  parseScheme,
} from "../../helpers/scheme";
import { getBpr } from "../../helpers/bench";
import styles from "./index.module.scss";

const schemes = [
  "5-4-1",
  "5-3-2",
  "5-2-3",
  "4-3-3",
  "4-4-2",
  "4-5-1",
  "3-4-3",
  "3-5-2",
];

const SchemePicker = ({ active, hidden }) => {
  const [scheme, setScheme] = useState(null);

  const { squad, setSquad } = useContext(SquadContext);
  const { players } = useContext(AdminContext);

  useEffect(() => {
    const currentScheme = parseScheme(squad);
    setScheme(currentScheme);
  }, [squad]);

  const onSchemeSelect = (event) => {
    const newScheme = event.target.innerText;
    const newSquad = getSquadAccordingToNewScheme(newScheme, squad, players);
    const newSquadWithBpr = {
      ...newSquad,
      bpr: getBpr(newSquad?.reserve, squad?.bpr),
    };
    setSquad(newSquadWithBpr);
  };

  return (
    <Dropdown
      className={styles.dropdown}
      style={{
        position: "relative",
        display: hidden ? "none" : "initial",
      }}
    >
      <Dropdown.Toggle
        disabled={!active}
        variant="success"
        id="dropdown-basic"
        className={styles.toggle}
      >
        {scheme}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ minWidth: 76 }}>
        {schemes.map(
          (item, index) =>
            scheme !== item && (
              <Dropdown.Item key={index} onClick={onSchemeSelect}>
                {item}
              </Dropdown.Item>
            )
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SchemePicker;
