import isEqual from "lodash.isequal";
import { getPlayerById } from "./player";
import { getSchemePositionsMap } from "./scheme";
import { ATTACKER, DEFENDER, GOALKEEPER, MIDFIELDER } from "../constants";
import { MAX_FIELD_BENCH_PLAYERS } from "./bench";

export const getReserveList = (squad) => {
  if (!squad) return [];
  const list = Object.keys(squad?.reserve).reduce(
    (acc, current) => acc.concat(squad?.reserve[current]),
    []
  );
  return list;
};

export const getReservePosition = (scheme, index) => {
  const positionsMap = getSchemePositionsMap(scheme);
  const reserveOffset = 11;

  return positionsMap[reserveOffset + index] || null;
};

export const squadToList = (squad) => {
  if (!squad) return [];
  let list = []
    .concat(
      Object.keys(squad?.main).reduce(
        (acc, key) => acc.concat(squad?.main?.[key]),
        []
      )
    )
    .concat(
      Object.keys(squad?.reserve).reduce(
        (acc, key) => acc.concat(squad?.reserve?.[key]),
        []
      )
    );
  return list;
};

export const isSquadReadyToPlay = (flatList, playersNumber, squad) => {
  const isFullSquad = playersNumber === flatList.length;
  const hasCapitan = Boolean(squad?.cap);
  const hasViceCapitan = Boolean(squad?.vcap);
  const hasBpr = squad?.bpr?.length === MAX_FIELD_BENCH_PLAYERS;

  return isFullSquad && hasCapitan && hasViceCapitan && hasBpr;
};

export const getSquadMessage = (
  flatList,
  playersNumber,
  squad,
  squadSnapshot,
  activeDeadline,
  players,
  numberOfTeamPlayers,
  currentRound,
  isWinterBreakEnabled,
  lastRound
) => {
  let message = "";
  let icon = "";
  const isFullSquad = playersNumber === flatList.length;
  const hasCapitan = Boolean(squad?.cap);
  const hasViceCapitan = Boolean(squad?.vcap);
  const isSeasonFinished = lastRound < currentRound;
  const validPlayerPositions = isValidPlayerPositions(squad, players);

  if (!activeDeadline) {
    if (isSeasonFinished) {
      message = "Сезон завершено";
    } else if (isWinterBreakEnabled) {
      message = "Міжсезоння: управління складом тимчасово заблоковано.";
    } else {
      message = "Тур триває: управління складом тимчасово заблоковано.";
    }
    icon = "lock";
  } else if (!isFullSquad) {
    message = "Склад не сформовано";
    icon = "x-circle";
  } else if (!hasCapitan) {
    message = "Капітана не призначено";
    icon = "exclamation-octagon-fill";
  } else if (!hasViceCapitan) {
    message = "Віце-капітана не призначено";
    icon = "exclamation-octagon-fill";
  } else if (!isValidPlayerTeams(squad, players, numberOfTeamPlayers)) {
    message = "Клубний ліміт перевищено. Зробіть трансфери";
    icon = "exclamation-circle-fill";
  } else if (!validPlayerPositions?.isValid) {
    message = `Помилка складу. Щоб виправити - продайте ${
      getPlayerById(players, validPlayerPositions?.details)?.LastName
    }`;
    icon = "exclamation-circle-fill";
  } else if (!isEqual(squad, squadSnapshot)) {
    message = "Збережіть зміни";
    icon = "exclamation-circle-fill";
  } else {
    message = "Склад готовий";
    icon = "check-circle";
  }

  return [icon, message];
};

export const getSquadDiffNumber = (squad, squadSnapshot) => {
  const squadFlatList = squadToList(squad);
  const squadSnapshotFlatList = squadToList(squadSnapshot);

  const difference = squadSnapshotFlatList.filter(
    (x) => !squadFlatList.includes(x)
  );

  return difference.length;
};

export const isTransferAllowed = (squad, squadSnapshot, allowedTransfers) => {
  const squadDiffNumber = getSquadDiffNumber(squad, squadSnapshot);
  return allowedTransfers > squadDiffNumber;
};

const getOccurrence = (array, value) => {
  return array.filter((v) => v === value).length;
};

export const getDisallowedTeamIds = (
  players,
  flatList,
  numberOfTeamPlayers
) => {
  const playerTeamsFlatList = flatList.map(
    (item) => getPlayerById(players, item).teamId
  );

  let result = [];

  playerTeamsFlatList.forEach((listItem) => {
    if (
      getOccurrence(playerTeamsFlatList, listItem) > numberOfTeamPlayers - 1 &&
      !result.includes(listItem)
    ) {
      result.push(listItem);
    }
  });

  return result;
};

export const isValidPlayerTeams = (squad, s3Players, numberOfTeamPlayers) => {
  const flatList = squadToList(squad);
  const playerTeamsFlatList = flatList.map(
    (item) => getPlayerById(s3Players, item).teamId
  );

  let result = true;

  playerTeamsFlatList.forEach((listItem) => {
    if (getOccurrence(playerTeamsFlatList, listItem) > numberOfTeamPlayers) {
      result = false;
    }
  });

  return result;
};

export const isValidPlayerPositions = (squad, players) => {
  const mainGoalkeepers = squad?.main?.GK?.filter((item) => item !== null).find(
    (item) => getPlayerById(players, item)?.position !== GOALKEEPER
  );
  const mainDefenders = squad?.main?.D?.filter((item) => item !== null).find(
    (item) => getPlayerById(players, item)?.position !== DEFENDER
  );
  const mainMidfielders = squad?.main?.MD?.filter((item) => item !== null).find(
    (item) => getPlayerById(players, item)?.position !== MIDFIELDER
  );

  const mainAttackers = squad?.main?.ST?.filter((item) => item !== null).find(
    (item) => getPlayerById(players, item)?.position !== ATTACKER
  );

  const reserveGoalkeepers = squad?.reserve?.GK?.filter(
    (item) => item !== null
  ).find((item) => getPlayerById(players, item)?.position !== GOALKEEPER);

  const reserveDefenders = squad?.reserve?.D?.filter(
    (item) => item !== null
  ).find((item) => getPlayerById(players, item)?.position !== DEFENDER);

  const reserveMidfielders = squad?.reserve?.MD?.filter(
    (item) => item !== null
  ).find((item) => getPlayerById(players, item)?.position !== MIDFIELDER);

  const reserveAttackers = squad?.reserve?.ST?.filter(
    (item) => item !== null
  ).find((item) => getPlayerById(players, item)?.position !== ATTACKER);

  const isValid =
    !mainGoalkeepers &&
    !mainDefenders &&
    !mainMidfielders &&
    !mainAttackers &&
    !reserveGoalkeepers &&
    !reserveDefenders &&
    !reserveMidfielders &&
    !reserveAttackers;
  return {
    isValid,
    details:
      mainGoalkeepers ||
      mainDefenders ||
      mainMidfielders ||
      mainAttackers ||
      reserveGoalkeepers ||
      reserveDefenders ||
      reserveMidfielders ||
      reserveAttackers,
  };
};

export const getPlayerScoresInCurrentRound = (playerId, squadScores) => {
  return (
    squadScores.find((scoreItem) => scoreItem.playerId === playerId)?.current ||
    0
  );
};

export const getTransfers = (prevList, currentList) => {
  let inTransfers = [];
  let outTransfers = [];

  currentList
    .filter((playerId) => playerId != null)
    .forEach((playerId) => {
      if (!prevList.includes(playerId)) {
        inTransfers.push(playerId);
      }
    });

  prevList
    .filter((playerId) => playerId != null)
    .forEach((playerId) => {
      if (!currentList.includes(playerId)) {
        outTransfers.push(playerId);
      }
    });

  return {
    inTransfers,
    outTransfers,
  };
};

export const getCurrentRoundPts = (squadScores) => {
  return squadScores
    .filter((v, i) => v.calc)
    .reduce((acc, value) => acc + value.current, 0);
};

export const isPlayerTeamWillNotPlay = (roundMatches, teamId) => {
  return (
    roundMatches.filter(
      (match) => match.FirstTeam === teamId || match.SecondTeam === teamId
    ).length === 0
  );
};

export const getCalculatedTeamIds = (roundMatches) =>
  roundMatches
    .filter((match) => match.Calculated)
    .reduce(
      (acc, match) => acc.concat([match.FirstTeam, match.SecondTeam]),
      []
    );

export const getRuleText = (ruleKey) => {
  switch (ruleKey) {
    case "UP_TO_60_MIN":
      return "Зіграв менше 60 хвилин";
    case "60_MIN_OR_MORE":
      return "Зіграв більше 60 хвилин";
    case "GOALS_BY_GK":
      return "Голи голкіпера";
    case "GOALS_BY_DEF":
      return "Голи захисника";
    case "GOALS_BY_MD":
      return "Голи півзахисника";
    case "GOALS_BY_ST":
      return "Голи нападника";
    case "PENALTY_GOALS_BY_GK":
      return "Голи з пенальті голкіпера";
    case "PENALTY_GOALS_BY_DEF":
      return "Голи з пенальті захисника";
    case "PENALTY_GOALS_BY_MD":
      return "Голи з пенальті півзахисника";
    case "PENALTY_GOALS_BY_ST":
      return "Голи з пенальті нападника";
    case "GOAL_ASSISTS_BY_GK":
      return "Асисти голкіпера";
    case "GOAL_ASSISTS_BY_DEF":
      return "Асисти захисника";
    case "GOAL_ASSISTS_BY_MD":
      return "Асисти півзахисника";
    case "GOAL_ASSISTS_BY_ST":
      return "Асисти нападника";
    case "CLEAN_SHEET_BY_GK":
      return "Cухий матч команди голкіпера";
    case "CLEAN_SHEET_BY_DEF":
      return "Cухий матч команди захисника";
    case "CLEAN_SHEET_BY_MD":
      return "Сухий матч команди півзахисника";
    case "CLEAN_SHEET_BY_ST":
      return "Сухий матч команди нападника";
    case "FOR_EVERY_2_GOALS_CONSEDED_BY_GK_OR_DEF":
      return "За кожні командні пропущені 2 голи";
    case "FOR_EVERY_2_GOALS_SCORED_BY_MD_OR_ST":
      return "За кожні командні забиті 2 голи";
    case "STRONG_WILLED_VICTORY":
      return "Вольова перемога";
    case "FOR_YELLOW_CARDS":
      return "Жовті картки";
    case "FOR_RED_CARDS":
      return "Червоні картки";
    case "FOR_OWN_GOALS":
      return "Автоголи";
    case "TECH_WINNER_GK_OR_DEF":
      return "Технічна перемога голкіпера або захисника";
    case "TECH_WINNER_MD":
      return "Технічна перемога півзахисника";
    case "TECH_WINNER_ST":
      return "Технічна перемога нападника";
    case "PENALTY_MISSED":
      return "Не забив з пенальті";
    case "PENALTY_SAVED":
      return "Не дозволив забити з пенальті";
    case "PENALTY_EARNS":
      return "Заробив пенальті в чужі ворота";
    case "PENALTY_FOULS":
      return "Заробив пенальті в свої ворота";
    case "FOR_MVP_BONUS":
      return "Бонус за кращих гравців матчу";
    case "FOR_GK_SAVES":
      return "За кожні три голкіперські сейви";
    default:
      return ruleKey;
  }
};

export const listToSquad = (list, bpr = []) => {
  const mainGk = [];
  const mainDef = [];
  const mainMid = [];
  const mainAtt = [];

  const reserveGk = [];
  const reserveDef = [];
  const reserveMid = [];
  const reserveAtt = [];

  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    if (i < 11) {
      if (list[i].position === GOALKEEPER) {
        mainGk.push(item.id);
      }

      if (list[i].position === DEFENDER) {
        mainDef.push(item.id);
      }

      if (list[i].position === MIDFIELDER) {
        mainMid.push(item.id);
      }

      if (list[i].position === ATTACKER) {
        mainAtt.push(item.id);
      }
    } else {
      if (list[i].position === GOALKEEPER) {
        reserveGk.push(item.id);
      }

      if (list[i].position === DEFENDER) {
        reserveDef.push(item.id);
      }

      if (list[i].position === MIDFIELDER) {
        reserveMid.push(item.id);
      }

      if (list[i].position === ATTACKER) {
        reserveAtt.push(item.id);
      }
    }
  }
  return {
    main: {
      GK: mainGk,
      D: mainDef,
      MD: mainMid,
      ST: mainAtt,
    },
    reserve: {
      GK: reserveGk,
      D: reserveDef,
      MD: reserveMid,
      ST: reserveAtt,
    },
    cap: list.find((item) => item.isCap)?.id ?? null,
    vcap: list.find((item) => item.isViceCap)?.id ?? null,
    bpr,
  };
};

export const isActiveCapitan = (
  playerId,
  cap,
  vcap,
  calcPlayers,
  selectedDetails,
  isHistoricalView
) => {
  let activePlayerIds = [];
  let isCapDone = false;

  if (isHistoricalView) {
    activePlayerIds = selectedDetails
      .filter((player) => player?.Calc && player?.Played && !player?.Replace)
      .map((player) => player.PlayerId);

    isCapDone =
      selectedDetails.filter((player) => player.PlayerId === cap && player.Done)
        .length > 0;
  } else {
    activePlayerIds = calcPlayers
      .filter((player) => player?.calc && player?.played && !player?.replace)
      .map((player) => player.playerId);
    isCapDone =
      calcPlayers.filter((player) => player.playerId === cap && player.done)
        .length > 0;
  }

  if (playerId === cap && activePlayerIds.includes(playerId)) {
    return true;
  } else if (
    playerId === vcap &&
    activePlayerIds.includes(playerId) &&
    isCapDone &&
    !activePlayerIds.includes(cap)
  ) {
    return true;
  }

  return false;
};
