import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import styles from "./index.module.scss";
import { Button } from "react-bootstrap";
import { useMutation } from "react-query";
import API from "../../api";
import { MessageContext } from "../../providers/MessageProvider";
import { Spinner } from "react-bootstrap";

const RevertSquad = ({ show, onHide }) => {
  const { showMessage } = useContext(MessageContext);

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      return API.revertSquad();
    },
    onSuccess: (response, variables, context) => {
      onHide();
      window.location.reload();
    },
    onError: () => {
      onHide();
      showMessage(
        "danger",
        "Помилка операції. Спробуйте ще раз або зверніться до організаторів гри."
      );
    },
  });

  return (
    <Modal show={show} size="md" backdrop="static" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="flex">
          <i className="bi bi-trash"></i>&nbsp;Скидання складу
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <>
          <p>
            Ви збираєтесь скинути ваш склад до стану, який був на момент
            закінчення дедлайну минулого туру. Ця дія доступна протягом туру{" "}
            <u>лише один раз</u>.
          </p>
          <p style={{ color: "red" }}>
            Усі ваші останні заміни, трансфери, зміни схеми, капітанів та
            пріоритетів лави запасних будуть втрачені.
          </p>
          <p>Бажаєте продовжити?</p>
        </>

        <div className={styles.actions}>
          <Button
            disabled={isLoading}
            onClick={() => mutate()}
            variant="secondary"
            className={`${styles.btn} ${styles.yes}`}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <i className="bi bi-check-lg"></i>
            )}
            &nbsp;Так
          </Button>
          <Button onClick={onHide} variant="outline" className={styles.btn}>
            <i className="bi bi-sign-stop"></i>&nbsp;Ні
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RevertSquad;
