import React, { useState, useEffect, useContext, useCallback } from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import styles from "./index.module.scss";
import { getSplittedName } from "../../helpers/user";
import { Badge } from "react-bootstrap";
import FootballField from "../FootballField";
import { useNavigate } from "react-router-dom";
import PlayerRoundInfo from "../../modals/PlayerRoundInfo";
import { getPlayerById } from "../../helpers/player";
import { AdminContext } from "../../providers/AdminProvider";
import { SettingsContext } from "../../providers/SettingsProvider";
import { getTeamLogoUrl, rndInt } from "../../helpers/utils";
import useIsMobile from "../../hooks/useIsMobile";
import Pin from "../../components/Pin";

const DetailsView = ({ user, items }) => {
  const [selectedRound, setSelectedRound] = useState(null);
  const [selectedSquad, setSelectedSquad] = useState(null);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [selectedPts, setSelectedPts] = useState(null);
  const [playerRoundModal, setPlayerRoundModal] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [moreEnabled, setMoreEnabled] = useState(false);
  const isMobile = useIsMobile();

  const { players } = useContext(AdminContext);
  const { currentRound: activeRound } = useContext(SettingsContext);

  const onHideModal = () => {
    setPlayerRoundModal(false);
  };

  const onPlayerClick = ({ playerId }) => {
    const player = getPlayerById(players, playerId);
    setSelectedPlayer(player);
    setPlayerRoundModal(true);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (!items.length) return;
    const lastActiveRound = items.filter((item) => item?.Details?.length > 0)[0]
      ?.Round;
    const currentRound = lastActiveRound ?? items[0].Round;
    const targetRecord = items.find((item) => item.Round === currentRound);
    const squad = targetRecord?.Squad;
    const details = targetRecord?.Details;
    setSelectedSquad(squad);
    setSelectedDetails(details);
    setSelectedRound(currentRound);
    setSelectedPts(targetRecord?.Pts);
  }, [items]);

  const onRoundClick = (round) => {
    const targetRecord = items.find((item) => item.Round === round);
    const squad = targetRecord?.Squad;
    const details = targetRecord?.Details;
    setSelectedPts(targetRecord?.Pts);
    setSelectedSquad(squad);
    setSelectedDetails(details);
    setSelectedRound(round);

    if (isMobile) {
      const element = document.getElementById("ftb-field");
      var headerOffset = 158;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const showMore = useCallback(() => {
    setMoreEnabled(true);
  }, [setMoreEnabled]);

  return (
    <Grid
      fluid
      style={{ paddingLeft: 0, paddingRight: 0 }}
      className="page-offset"
    >
      <div className={styles.coach}>
        <div className={styles.person}>
          <img className={styles.logo} src={user?.Logo} alt="" />
          <div className={styles.userName}>
            {getSplittedName(user?.FullName)}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div className={styles.favTeam}>
            <i className="bi bi-suit-heart"></i>&nbsp;
            <img
              style={{ height: 30, objectFit: "contain", filter: "sepia(1)" }}
              src={getTeamLogoUrl(user.FavTeamId, rndInt())}
              alt=""
            />
          </div>
          <div className={styles.circleCnt}>
            <div className={styles.circle}>
              <div className={styles.circleItem}>{user?.Points}</div>
            </div>
          </div>
        </div>
      </div>

      <Row style={{ marginBottom: 8 }}>
        <Col
          xs={12}
          md={6}
          className={`${styles.rounds} ${
            !moreEnabled && items.length > 5 ? styles.moreEnabled : null
          }`}
        >
          {items
            .filter((item) => item?.Details?.length > 0)
            .map((item) => (
              <div
                key={item.Round}
                className={`${
                  item.Round === activeRound ? styles.itemActive : null
                } ${styles.itemCnt}`}
              >
                <div
                  key={item.Round}
                  className={`${styles.listItem} ${
                    item.Round === selectedRound ? styles.active : ""
                  }`}
                  onClick={() => onRoundClick(item.Round)}
                >
                  <div className={styles.flexItem}>
                    <Badge bg="success">{item.Rank}</Badge>
                    <div className={styles.person}>
                      <div className={styles.caption}>Тур {item.Round}</div>
                    </div>
                  </div>

                  <div
                    style={{ fontWeight: "bold" }}
                    className={styles.flexItem}
                  >
                    {item.Pts}
                  </div>
                </div>
                <div className={styles.standingsCnt}>
                  <i
                    className={`bi bi-stars ${styles.standingsBtn}`}
                    onClick={() => navigate(`/rounds/${item.Round}`)}
                  ></i>
                  <i
                    className={`bi bi-graph-up-arrow ${styles.standingsBtn}`}
                    onClick={() => navigate(`/standings/${item.Round}`)}
                  ></i>
                </div>
              </div>
            ))}
        </Col>
        {!moreEnabled && items.length > 5 && (
          <div className={styles.showMore} onClick={showMore}>
            <i className="bi bi-chevron-double-down"></i>&nbsp; Показати всі
            тури
          </div>
        )}
        <Col xs={12} md={6}>
          {selectedSquad !== null && (
            <>
              <h5
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span>
                  <i className="bi bi-person-fill"></i>&nbsp;Склад
                </span>
                <Pin
                  leftSideText={`ТУР ${selectedRound}`}
                  rightSideText={selectedPts}
                />
              </h5>
              <FootballField
                squad={selectedSquad}
                onItemClick={onPlayerClick}
                isGoalkeeperClickAllowed={true}
                isDefenderClickAllowed={true}
                isMidfielderClickAllowed={true}
                isAttackerClickAllowed={true}
                action=""
                selectedItem={null}
                outPlayer={null}
                selectedDetails={selectedDetails}
                isHistoricalView={true}
              />
            </>
          )}
        </Col>
      </Row>
      <PlayerRoundInfo
        show={playerRoundModal}
        onHide={onHideModal}
        player={selectedPlayer}
        round={selectedRound}
      />
    </Grid>
  );
};

export default DetailsView;
