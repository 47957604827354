const compare = (a1, a2) =>
  a1.length === a2.length &&
  a1.every((element, index) => element === a2[index]);

const getBenchList = (reserve) => {
  return reserve["D"]
    .concat(reserve["MD"])
    .concat(reserve["ST"])
    .filter((item) => item !== null);
};

export const MAX_FIELD_BENCH_PLAYERS = 3;

export const getBpr = (reserve, bpr = []) => {
  const benchList = getBenchList(reserve);
  const isFullBench = benchList.length === MAX_FIELD_BENCH_PLAYERS;
  const isBprEmpty = bpr.length === 0;

  if (isFullBench) {
    if (isBprEmpty) {
      return benchList;
    } else {
      if (compare(benchList, bpr)) {
        return bpr;
      } else {
        const newOnTheBench = benchList.filter((x) => !bpr.includes(x));

        return bpr.map((item) =>
          benchList.includes(item) ? item : newOnTheBench.shift()
        );
      }
    }
  } else {
    return [];
  }
};
